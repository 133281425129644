import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import IconOLD from "../../components/UI/IconOLD";
import Spinner from "../../components/UI/Spinner";
import OrderDetails from "../../components/widgets/OrderDetails";
import usePublicOrder from "../../hooks/data/usePublicOrder";
import { useSearchQuery } from "../../hooks/functionality/useSearchQuery";
import PointsMap from "../../maps/PointsMap";
import { IconType, MapPoint } from "../../shared/types/internal";
import { formatDateString } from "../../shared/utility/date";
import {
    convertToNumberWithSpaces,
    isEnvironment,
} from "../../shared/utility/misc";
import { QUERY_PARAM } from "../../shared/values/enums";
import { GOOGLE_MAP_IDS } from "../../shared/values/google-map-ids";
import "./style.scss";

type Props = {};

function Tracking(props: Props) {
    const query = useSearchQuery();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [publicOrderId, setPublicOrderId] = useState("");

    useEffect(() => {
        const queryPublicId = query.get(QUERY_PARAM.PublicOrderId);
        if (!queryPublicId) {
            navigate("/");
            return;
        }
        setPublicOrderId(queryPublicId);
    }, [navigate, query]);

    const { publicOrder, status, refetch, isFetching } =
        usePublicOrder(publicOrderId);

    const mapPoints = useMemo(() => {
        if (!publicOrder) return [];

        const points: MapPoint[] = [];

        if (publicOrder.pickup_lat && publicOrder.pickup_lng) {
            points.push({
                address: publicOrder.pickup_address || "",
                location: {
                    lat: +publicOrder.pickup_lat,
                    lng: +publicOrder.pickup_lng,
                },
                label: "1",
                icon: "plane-departure",
            });
        }

        if (publicOrder.dropoff_lat && publicOrder.dropoff_lng) {
            points.push({
                address: publicOrder.dropoff_address || "",
                location: {
                    lat: +publicOrder.dropoff_lat,
                    lng: +publicOrder.dropoff_lng,
                },
                label: "2",
                icon: "race-flag",
            });
        }

        return points;
    }, [publicOrder]);

    return (
        <div className="tracking">
            {status !== "success" ? (
                <Spinner padding="10px" />
            ) : (
                <div className="content">
                    <div className="map-wrapper">
                        <PointsMap
                            mapId={GOOGLE_MAP_IDS.TrackingMap}
                            showDirections
                            points={mapPoints}
                            extraPoints={
                                publicOrder.driver?.current_lat &&
                                publicOrder.driver?.current_lng &&
                                isEnvironment("staging")
                                    ? [
                                          {
                                              address: "",
                                              location: {
                                                  lat: +publicOrder.driver
                                                      .current_lat,
                                                  lng: +publicOrder.driver
                                                      .current_lng,
                                              },
                                              label: "3",
                                              icon: "large-van",
                                          },
                                      ]
                                    : undefined
                            }
                        />
                    </div>
                    <div className="order-info">
                        <ButtonOLD
                            label={t("activeFleet.updateAllData")}
                            color={"secondary"}
                            width="25%"
                            isLoading={isFetching}
                            onClick={() => refetch()}
                            style={{
                                marginLeft: "auto",
                            }}
                        />
                        <OrderDetails
                            order={publicOrder}
                            getSections={(order) => [
                                [
                                    {
                                        title: t("tracking.pickupEta"),
                                        value: order.pickup_eta
                                            ? formatDateString(
                                                  order.pickup_eta,
                                                  {
                                                      showTime: true,
                                                  }
                                              )
                                            : undefined,
                                        hide:
                                            !order.pickup_eta ||
                                            !!order.arrived_at_pickup_at,
                                    },
                                    {
                                        title: t("tracking.actualPickup"),
                                        value: order.arrived_at_pickup_at
                                            ? formatDateString(
                                                  order.arrived_at_pickup_at,
                                                  {
                                                      showTime: true,
                                                  }
                                              )
                                            : undefined,
                                        hide: !order.arrived_at_pickup_at,
                                    },
                                    {
                                        title: t("tracking.dropoffEta"),
                                        value: order.dropoff_eta
                                            ? formatDateString(
                                                  order.dropoff_eta,
                                                  {
                                                      showTime: true,
                                                  }
                                              )
                                            : undefined,
                                        hide:
                                            !order.dropoff_eta ||
                                            !!order.arrived_at,
                                    },
                                    {
                                        title: t("tracking.actualDelivery"),
                                        value: order.arrived_at
                                            ? formatDateString(
                                                  order.arrived_at,
                                                  {
                                                      showTime: true,
                                                  }
                                              )
                                            : undefined,
                                        hide: !order.arrived_at,
                                    },
                                ],
                                [
                                    {
                                        title: t("popup.orderInfo.weightLabel"),
                                        value: order.weight_kg
                                            ? convertToNumberWithSpaces(
                                                  order.weight_kg,
                                                  "kg"
                                              )
                                            : "-",
                                        hide: !order.weight_kg,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.confirmOrder.numberOfPackagesLabel"
                                        ),
                                        value: order.additional_info
                                            ?.number_of_eu_pallets,
                                        hide: !order.additional_info
                                            ?.number_of_eu_pallets,
                                    },
                                    {
                                        title: t(
                                            "popup.confirmOrder.weightOfHeaviestPackageLabel"
                                        ),
                                        value: order.weight_of_heaviest_package,
                                        hide: !order.weight_of_heaviest_package,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.vehicleLabel"
                                        ),
                                        valueComponent: (
                                            <span>
                                                <IconOLD
                                                    type={
                                                        publicOrder.vehicle_type as IconType
                                                    }
                                                    style={{
                                                        color: "var(--text-color-light)",
                                                    }}
                                                />{" "}
                                                {order.vehicle?.friendlyname}
                                            </span>
                                        ),
                                        hide: !order.vehicle?.friendlyname,
                                    },
                                    {
                                        title: t(
                                            "createOrder.additionalVehicleTypeLabel"
                                        ),
                                        value: order.additional_info
                                            ?.side_loading
                                            ? t("general.yes")
                                            : t("general.no"),
                                        hide:
                                            order.vehicle?.car_name !==
                                            "large-van",
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.deliveryLabel"
                                        ),
                                        value: order.delivery?.friendlyname,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.confirmOrder.earliestPickupLabel"
                                        ),
                                        value: order.earliest_pickup
                                            ? formatDateString(
                                                  order.earliest_pickup,
                                                  {
                                                      showTime: true,
                                                  }
                                              )
                                            : "-",
                                        hide: !order.earliest_pickup,
                                    },
                                    {
                                        title: t(
                                            "popup.confirmOrder.latestDeliveryLabel"
                                        ),
                                        value: order.latest_delivery
                                            ? formatDateString(
                                                  order.latest_delivery,
                                                  {
                                                      showTime: true,
                                                  }
                                              )
                                            : "-",
                                        hide: !order.latest_delivery,
                                    },
                                ],
                                [
                                    {
                                        title: t("popup.orderInfo.fromLabel"),
                                        value: order.pickup_address,
                                    },
                                ],
                                [
                                    {
                                        title: t("popup.orderInfo.toLabel"),
                                        value: order.dropoff_address,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.orderNumberLabel"
                                        ),
                                        value: order.origin_order_id,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "popup.orderInfo.cargoContentLabel"
                                        ),
                                        value: order.cargo_content,
                                    },
                                ],
                            ]}
                            getShowMoreSections={(order) => [
                                [
                                    {
                                        title: t(
                                            "createOrder.additionalInfoCompany"
                                        ),
                                        value: order.additional_info
                                            ?.consignee_company,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "createOrder.additionalInfoUnloading"
                                        ),
                                        value: order.additional_info
                                            ?.estimated_offload_time,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "createOrder.additionalInfoAdditionalContact"
                                        ),
                                        valueComponent:
                                            publicOrder.additional_info?.contacts?.map(
                                                (c, i) => (
                                                    <p
                                                        className="value text-sm"
                                                        key={i}
                                                    >
                                                        <span>
                                                            {c.consignee_name}
                                                        </span>
                                                        <span>
                                                            {c.consignee_phone}
                                                        </span>
                                                    </p>
                                                )
                                            ),
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "createOrder.additionalInfoSpecificDropoff"
                                        ),
                                        value: publicOrder.additional_info
                                            ?.offload_instructions,
                                    },
                                ],
                                [
                                    {
                                        title: t(
                                            "createOrder.additionalInfoTotalCargo"
                                        ),
                                        valueComponent:
                                            publicOrder.additional_info?.cargo_dimensions?.map(
                                                (cd, i) => (
                                                    <p
                                                        className="value text-sm"
                                                        key={i}
                                                    >
                                                        <span>
                                                            {t(
                                                                "createOrder.additionalInfoDropdownLengthLabel"
                                                            )}
                                                            :{cd.length || "-"}
                                                        </span>
                                                        <span>
                                                            {t(
                                                                "createOrder.additionalInfoDropdownWidthLabel"
                                                            )}
                                                            :{cd.width || "-"}
                                                        </span>
                                                        <span>
                                                            {t(
                                                                "createOrder.additionalInfoDropdownHeightLabel"
                                                            )}
                                                            :{cd.height || "-"}
                                                        </span>
                                                        <span>
                                                            {t(
                                                                "createOrder.additionalInfoDropdownWeightLabel"
                                                            )}
                                                            : {cd.weight || "-"}
                                                        </span>
                                                    </p>
                                                )
                                            ),
                                    },
                                ],
                            ]}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Tracking;
