import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import usePolylinePath from "../../../hooks/functionality/usePolylinePath";
import { StopDraft } from "../../../shared/types/api";
import {
    getLatLngCountMap,
    getLocationKey,
} from "../../../shared/utility/misc";
import { STOP_DRAFT_TYPE } from "../../../shared/values/enums";
import "./style.scss";

type Props = {
    stops: StopDraft[];
    onClick?: (stop: StopDraft) => void;
    onSelectStops?: (stops: { pickup: StopDraft; dropoff: StopDraft }) => void;
    onSelectMultipleStops?: (
        stops: { pickup: StopDraft; dropoff: StopDraft }[]
    ) => void;
    focusedStop?: StopDraft;
};

function StopMarkers(props: Props) {
    const { onSelectMultipleStops } = props;
    const { t } = useTranslation();

    const focusedStops = useMemo(() => {
        if (!props.focusedStop) return null;

        const pickup = props.stops.find(
            (s) =>
                s.group_id === props.focusedStop?.group_id &&
                s.stop_type_id === STOP_DRAFT_TYPE.Pickup
        );

        const dropoff = props.stops.find(
            (s) =>
                s.group_id === props.focusedStop?.group_id &&
                s.stop_type_id === STOP_DRAFT_TYPE.Dropoff
        );

        if (!pickup || !dropoff) return null;

        return { pickup, dropoff };
    }, [props.focusedStop, props.stops]);

    usePolylinePath({
        hide: !focusedStops,
        path: [
            {
                lat: +focusedStops?.pickup.lat!,
                lng: +focusedStops?.pickup.lng!,
            },
            {
                lat: +focusedStops?.dropoff.lat!,
                lng: +focusedStops?.dropoff.lng!,
            },
        ],
    });

    const latLngStopCounts = useMemo(
        () =>
            getLatLngCountMap(
                props.stops.map((stop) => ({
                    lat: +stop.lat!,
                    lng: +stop.lng!,
                    prefix: stop.stop_type_id.toString(),
                }))
            ),
        [props.stops]
    );

    const selectMultipleStopsHandler = useCallback(
        (stop: StopDraft) => {
            if (!onSelectMultipleStops) return;

            const stopsAtLocationWithSameType = props.stops.filter(
                (s) =>
                    getLocationKey(
                        {
                            lat: +s.lat!,
                            lng: +s.lng!,
                        },
                        stop.stop_type_id.toString()
                    ) ===
                    getLocationKey(
                        {
                            lat: +stop.lat!,
                            lng: +stop.lng!,
                        },
                        stop.stop_type_id.toString()
                    )
            );

            const groupIdsAtLocation = stopsAtLocationWithSameType.map(
                (s) => s.group_id
            );

            const pickups = props.stops.filter(
                (s) =>
                    groupIdsAtLocation.includes(s.group_id) &&
                    s.stop_type_id === STOP_DRAFT_TYPE.Pickup
            );

            const selection: { pickup: StopDraft; dropoff: StopDraft }[] = [];

            for (let i = 0; i < pickups.length; i++) {
                const pickup = pickups[i];

                const dropoff = props.stops.find(
                    (s) =>
                        s.group_id === pickup.group_id &&
                        s.stop_type_id === STOP_DRAFT_TYPE.Dropoff
                );

                if (!dropoff) continue;

                selection.push({ pickup, dropoff });
            }

            onSelectMultipleStops(selection);
        },
        [onSelectMultipleStops, props.stops]
    );

    return (
        <>
            {props.stops.map((stop) => {
                const stopLocation = {
                    lat: +stop.lat!,
                    lng: +stop.lng!,
                };

                const latLngCount =
                    latLngStopCounts[
                        getLocationKey(
                            stopLocation,
                            stop.stop_type_id.toString()
                        )
                    ];

                return (
                    <AdvancedMarker
                        key={stop.id}
                        position={stopLocation}
                        onClick={() => props.onClick?.(stop)}
                        zIndex={
                            focusedStops?.pickup.id === stop.id ||
                            focusedStops?.dropoff.id === stop.id
                                ? 1
                                : 0
                        }
                    >
                        <div
                            className={
                                "stop-marker" +
                                (latLngCount > 1 ? " multiple" : "")
                            }
                            style={{
                                backgroundColor:
                                    stop.stop_type_id === STOP_DRAFT_TYPE.Pickup
                                        ? "var(--color-primary-700)"
                                        : "var(--color-primary-500)",
                            }}
                        >
                            {latLngCount > 1 ? latLngCount : null}
                        </div>
                        {props.focusedStop?.id === stop.id ? (
                            <div className="info-window">
                                <div className="top">
                                    <p
                                        className="text-2xs"
                                        style={{
                                            color: "var(--color-neutral-600)",
                                        }}
                                    >
                                        {stop.order_number}
                                    </p>
                                    <p className="text-xs">
                                        {stop.street_address}
                                    </p>
                                </div>

                                <button
                                    type="button"
                                    className="subtle-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (!focusedStops) return;
                                        props.onSelectStops?.({
                                            pickup: focusedStops.pickup,
                                            dropoff: focusedStops.dropoff,
                                        });
                                    }}
                                >
                                    {t("bigVolume.selectStopMap")}
                                </button>

                                {latLngCount > 1 ? (
                                    <button
                                        type="button"
                                        className="subtle-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (!props.focusedStop) return;
                                            selectMultipleStopsHandler(
                                                props.focusedStop
                                            );
                                        }}
                                    >
                                        {t("bigVolume.selectMultipleStopMap", {
                                            count: latLngCount,
                                        })}
                                    </button>
                                ) : null}
                            </div>
                        ) : null}
                    </AdvancedMarker>
                );
            })}
        </>
    );
}

export default StopMarkers;
