import { DragDropContext, OnDragEndResponder } from "@hello-pangea/dnd";
import { useMemo } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useSelector } from "react-redux";
import useDrivers from "../../hooks/data/useDrivers";
import useDirections from "../../hooks/functionality/useDirections";
import { StopDraft, StopDraftsTour } from "../../shared/types/api";
import { KeyString } from "../../shared/types/internal";
import { ReduxState } from "../../shared/types/redux";
import { NEW_TOUR_COLUMN_ID } from "../../shared/values/enums";
import TourColumn from "../columns/TourColumn";
import "./style.scss";

type Props = {
    tours: StopDraftsTour[];
    stops: StopDraft[];
    filterDate: Date | null;
    onDragEnd: OnDragEndResponder;
    tourColorMap?: KeyString<{
        primary: string;
        accent: string;
    }>;

    // Loading
    columnIdsLoading: string[];
    stopIdsLoading: number[];

    onStopCardClick?: ({
        pickup,
        dropoff,
        groupedStops,
    }: {
        pickup: StopDraft;
        dropoff: StopDraft;
        groupedStops?: {
            pickup: StopDraft;
            dropoff: StopDraft;
        }[];
    }) => void;
    onClearStops?: () => void;
    onSelectTourId?: (tourId: number) => void;
};

function ToursDragDrop(props: Props) {
    const { hiddenDriverIds } = useSelector(
        (state: ReduxState) => state.fleetPlanner.settings
    );
    const { drivers } = useDrivers();
    const { pathLegsDurationInMinutes } = useDirections({
        path: props.stops.map((stop) => ({
            lat: +stop.lat!,
            lng: +stop.lng!,
        })),
    });

    const filteredDriver = useMemo(() => {
        if (!drivers) return [];

        return drivers.filter(
            (driver) => hiddenDriverIds?.[driver.mt_driver_id || ""] !== 1
        );
    }, [drivers, hiddenDriverIds]);

    return (
        <div className="tours-drag-drop">
            <ScrollContainer
                className="content"
                ignoreElements=".stop-card, .dropdown-wrapper, .input"
                vertical={false}
                hideScrollbars={false}
            >
                <DragDropContext onDragEnd={props.onDragEnd}>
                    <TourColumn
                        key={NEW_TOUR_COLUMN_ID}
                        id={NEW_TOUR_COLUMN_ID}
                        drivers={filteredDriver}
                        stops={props.stops}
                        filterDate={props.filterDate}
                        stopIdsLoading={props.stopIdsLoading}
                        isNewTourColumn
                        tourColor={
                            props.tourColorMap?.[NEW_TOUR_COLUMN_ID].primary
                        }
                        onStopCardClick={props.onStopCardClick}
                        onClearStops={props.onClearStops}
                        legsDurationInMinutes={pathLegsDurationInMinutes}
                        onSelectTourId={props.onSelectTourId}
                    />
                    {props.tours.map((tour) => (
                        <TourColumn
                            key={tour.tour_id}
                            id={tour.tour_id.toString()}
                            tour={tour}
                            drivers={filteredDriver}
                            stops={tour.stops}
                            filterDate={props.filterDate}
                            isLoading={props.columnIdsLoading.includes(
                                tour.tour_id.toString()
                            )}
                            stopIdsLoading={props.stopIdsLoading}
                            tourColor={
                                props.tourColorMap?.[tour.tour_id].primary
                            }
                            onStopCardClick={props.onStopCardClick}
                            onSelectTourId={props.onSelectTourId}
                        />
                    ))}
                </DragDropContext>
            </ScrollContainer>
        </div>
    );
}

export default ToursDragDrop;
