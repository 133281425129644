import {
    faBars,
    faPaperPlane,
    faSpinnerThird,
    faVideoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";
import { useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getMe, inviteCustomer, updateProfile } from "../../api/auth";
import alrikLogotype from "../../assets/logotype/logo-primary-no-padding.svg";
import Button from "../../components/buttons/Button";
import NavButton from "../../components/buttons/NavButton";
import Dropdown from "../../components/inputs/Dropdown";
import Input from "../../components/inputs/Input";
import Popup from "../../hoc/Popup";
import { InviteColleagueForm, inviteColleagueForm } from "../../schemas/auth";
import { User } from "../../shared/types/api";
import { NavItem } from "../../shared/types/internal";
import { ReduxState } from "../../shared/types/redux";
import { onFormError } from "../../shared/utility/misc";
import { API_ERROR, ROUTE } from "../../shared/values/enums";
import { setUser } from "../../store/slices/auth";
import "./style.scss";

type Props = {};

function Navbar(props: Props) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const {
        control,
        handleSubmit,
        reset: resetInputs,
    } = useForm<InviteColleagueForm>({
        resolver: zodResolver(inviteColleagueForm),
        defaultValues: {
            email: "",
        },
    });

    const { mutate: inviteColleagueHandler, isPending: isInvitingColleague } =
        useMutation({
            mutationFn: async ({ email }: InviteColleagueForm) => {
                await inviteCustomer({ email, source: "connect" });

                return email;
            },
            onSuccess: () => {
                toast.success(t("navbar.inviteSuccess"));
                resetInputs();
            },
            onError: (error) => {
                if (isAxiosError(error)) {
                    const axiosError = error as AxiosError<{ detail: string }>;

                    if (
                        axiosError.response?.data.detail ===
                        API_ERROR.EmailInUse
                    ) {
                        toast.error(t("errorMessage.emailAlreadyExists"));
                        return;
                    }
                }

                toast.error(t("errorMessage.unknown"));
            },
        });

    const { mutate: changeLocationHandler, isPending: isChangingLocation } =
        useMutation({
            mutationFn: async ({
                locationId,
                user,
            }: {
                locationId: number;
                user: User;
            }) => {
                await updateProfile({
                    firstName: user.first_name,
                    lastName: user.last_name,
                    phone: user.customer_phone,
                    locationId,
                });
            },
            onSuccess: async () => {
                const res = await getMe();

                dispatch(setUser(res.data));

                queryClient.removeQueries({
                    type: "all",
                });

                toast.success(t("successMessage.changeLocation"));
            },
            onError: () => {
                toast.success(t("errorMessage.changeLocationFailed"));
            },
        });

    const handleNavLinkClick = useCallback(
        (event: React.MouseEvent, needsCompany: boolean) => {
            setIsSidebarOpen(false);

            if (!user?.company_entity && needsCompany) {
                toast.error(t("errorMessage.noCompany"));
                event.preventDefault();
                return;
            }
            if (!user?.location_entity && needsCompany) {
                toast.error(t("errorMessage.noLocation"));
                event.preventDefault();
            }
        },
        [t, user?.company_entity, user?.location_entity]
    );

    const navItems = useMemo(() => {
        const fodNavItems: NavItem[] = [
            {
                id: "new-order",
                path: ROUTE.NewOrder,
                label: t("navbar.fod.new"),
            },
            {
                id: "active-orders",
                path: ROUTE.ActiveOrders,
                label: t("navbar.fod.active"),
            },
            {
                id: "fulfilled-orders",
                path: ROUTE.FulfilledOrders,
                label: t("navbar.fod.history"),
            },
        ];

        const fpNavItems: NavItem[] = [
            {
                id: "active-fleet",
                path: ROUTE.ActiveFleet,
                label: t("fleet.active-fleet"),
            },
            {
                id: "big-volume",
                path: ROUTE.Cockpit,
                label: t("fleet.big-volume"),
                isBeta: true,
            },
            {
                id: "fleet-calendar",
                path: ROUTE.FleetCalendar,
                label: t("fleetPlanner.calendar"),
            },
            {
                id: "fulfilled-fleet-tours",
                path: ROUTE.FulfilledFleetTours,
                label: t("fleetPlanner.orderHistory"),
            },
            {
                id: "drivers",
                path: ROUTE.Drivers,
                label: t("fleetPlanner.drivers"),
            },
            {
                id: "dashboard",
                path: ROUTE.Dashboard,
                label: t("fleetPlanner.dashboard"),
            },
            {
                id: "fleet-settings",
                path: ROUTE.FleetSettings,
                label: t("fleetPlanner.settings"),
            },
        ];

        const navItems = [
            {
                label: t("navbar.orders"),
                path: ROUTE.Orders,
                needsCompany: true,
                subNavItems: fodNavItems,
            },
            {
                label: t("navbar.tours"),
                path: ROUTE.Fleet,
                needsCompany: true,
                subNavItems: fpNavItems,
            },
            {
                label: t("navbar.support"),
                path: ROUTE.Support,
                needsCompany: false,
                subNavItems: [],
            },
            {
                label: t("navbar.settings"),
                path: ROUTE.Settings,
                needsCompany: false,
                subNavItems: [],
            },
        ];

        return navItems;
    }, [t]);

    return (
        <nav className="navbar">
            <div className="navbar-desktop">
                <div className="left-bar">
                    <img src={alrikLogotype} alt="Alrik Logo" />
                    <div className="nav-actions">
                        <Dropdown
                            value={user?.location_entity?.id.toString() || null}
                            onSelect={({ value }) =>
                                user
                                    ? changeLocationHandler({
                                          user,
                                          locationId: +value,
                                      })
                                    : null
                            }
                            disabled={!!user?.company_entity?.hidden_locations}
                            isLoading={isChangingLocation}
                            isSearchable
                            width="185px"
                            options={
                                user?.company_entity?.locations
                                    .map((l) => ({
                                        label: l.name,
                                        value: l.id.toString(),
                                    }))
                                    .sort((a, b) =>
                                        a.label.localeCompare(b.label)
                                    ) || []
                            }
                        />
                        {user?.company_entity && (
                            <form
                                className="invite-form"
                                onSubmit={handleSubmit(
                                    (data) => inviteColleagueHandler(data),
                                    onFormError
                                )}
                            >
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Input
                                            type="email"
                                            value={value}
                                            onChange={onChange}
                                            placeholder={t(
                                                "navbar.invitePlaceholder"
                                            )}
                                            wrapperStyle={{
                                                paddingRight: "32px",
                                                boxShadow: "none",
                                            }}
                                        />
                                    )}
                                />
                                <button className="invite-submit-button">
                                    <FontAwesomeIcon
                                        icon={
                                            isInvitingColleague
                                                ? faSpinnerThird
                                                : faPaperPlane
                                        }
                                        style={
                                            {
                                                "--fa-animation-duration":
                                                    "0.5s",
                                            } as React.CSSProperties
                                        }
                                        color="var(--color-neutral-400)"
                                        size="lg"
                                        spin={isInvitingColleague}
                                    />
                                </button>
                            </form>
                        )}

                        <a
                            href="https://drive.google.com/file/d/19MlOG9Nd6NMj8MU3O_kSshIlVcC_2uDu/view?usp=drive_link"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                label={t("navbar.newUpdates")}
                                variant="secondary"
                                noSubmit
                                leadingIcon={faVideoCircle}
                            />
                        </a>
                    </div>
                </div>
                <div className="right-bar">
                    {navItems.map((nav) => (
                        <NavButton
                            key={nav.label}
                            label={nav.label}
                            to={nav.path}
                            subNavItems={nav.subNavItems}
                            onClick={(e) =>
                                handleNavLinkClick(e, nav.needsCompany)
                            }
                        />
                    ))}
                </div>
            </div>
            <div className="navbar-mobile">
                <div className="left-bar">
                    <img src={alrikLogotype} alt="Alrik Logo" />
                </div>
                <div className="right-bar">
                    <FontAwesomeIcon
                        icon={faBars}
                        size="2x"
                        color="var(--color-neutral-600)"
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    />
                </div>
            </div>
            <Popup
                showPopup={isSidebarOpen}
                onClose={() => setIsSidebarOpen(false)}
            >
                {navItems.map((item) => (
                    <div className="mobile-nav-wrapper" key={item.label}>
                        <p className="text-lg">{item.label}</p>
                        {item.subNavItems.length > 0 ? (
                            item.subNavItems.map((nav) => (
                                <NavButton
                                    key={nav.label}
                                    label={nav.label}
                                    to={nav.path}
                                    onClick={(e) =>
                                        handleNavLinkClick(e, item.needsCompany)
                                    }
                                />
                            ))
                        ) : (
                            <NavButton
                                key={item.label}
                                label={item.label}
                                to={item.path}
                                onClick={(e) =>
                                    handleNavLinkClick(e, item.needsCompany)
                                }
                            />
                        )}
                    </div>
                ))}
            </Popup>
        </nav>
    );
}

export default Navbar;
