import { ErrorBoundary } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { getMe } from "./api/auth";
import Spinner from "./components/UI/Spinner";
import ErrorComponent from "./components/widgets/ErrorComponent";
import Layout from "./hoc/Layout";
import NonAuthLayout from "./hoc/NonAuthLayout";
import useAnalytics from "./hooks/functionality/useAnalytics";
import ActiveFleet from "./pages/ActiveFleet";
import ActiveOrders from "./pages/ActiveOrders";
import BigVolume from "./pages/BigVolume";
import Drivers from "./pages/Drivers";
import ExternalCarrierOrder from "./pages/ExternalCarrierOrder";
import Fleet from "./pages/Fleet";
import FleetCalendar from "./pages/FleetCalendar";
import FleetDashboard from "./pages/FleetDashboard";
import FleetManualOrder from "./pages/FleetManualOrder";
import FleetSettings from "./pages/FleetSettings";
import FulfilledFleetTours from "./pages/FulfilledFleetTours";
import FulfilledOrders from "./pages/FulfilledOrders";
import NewOrder from "./pages/NewOrder";
import Orders from "./pages/Orders";
import PublicOrder from "./pages/PublicOrder";
import Settings from "./pages/Settings";
import Support from "./pages/Support";
import Tracking from "./pages/Tracking";
import TrackingRedirect from "./pages/TrackingRedirect";
import AuthCode from "./pages/auth/AuthCode";
import EmailSent from "./pages/auth/EmailSent";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ResetPassword from "./pages/auth/ResetPassword";
import Setup from "./pages/auth/Setup";
import memberstack from "./shared/services/memberstack";
import { User } from "./shared/types/api";
import { MemberstackMember } from "./shared/types/memberstack";
import { FleetPlannerSettings, ReduxState } from "./shared/types/redux";
import { API_ERROR, LOCAL_STORAGE_KEY, ROUTE } from "./shared/values/enums";
import { setUser } from "./store/slices/auth";
import { setFleetPlannerSettings } from "./store/slices/fleetPlanner";

function App() {
    useAnalytics();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const { user } = useSelector((state: ReduxState) => state.auth);

    const [isAuthLoading, setIsAuthLoading] = useState(true);

    const authSetup = useCallback(
        async (isAuthenticated: boolean) => {
            setIsAuthLoading(true);

            if (!isAuthenticated) {
                dispatch(setUser(null));
                queryClient.clear();
                setIsAuthLoading(false);
                return;
            }

            let user: User | null = null;
            try {
                const res = await getMe();
                user = res.data;
                dispatch(setUser(user));
            } catch (error) {
                if (isAxiosError(error)) {
                    if (
                        error.response?.data.detail !== API_ERROR.BadToken &&
                        error.response?.data.detail !== API_ERROR.SessionExpired
                    ) {
                        toast.error(t("errorMessage.fetchCustomerError"), {
                            duration: 20000,
                        });
                    }
                }
                dispatch(setUser(null));
                setIsAuthLoading(false);
                return;
            }

            const fleetPlannerSettingsString = localStorage.getItem(
                LOCAL_STORAGE_KEY.FleetPlannerSettings
            );

            if (fleetPlannerSettingsString) {
                const fleetPlannerSettings: FleetPlannerSettings = JSON.parse(
                    fleetPlannerSettingsString
                );
                dispatch(
                    setFleetPlannerSettings({
                        ...fleetPlannerSettings,
                        showAllDriversInCompany: user.company_entity
                            ?.show_location_orders
                            ? fleetPlannerSettings.showAllDriversInCompany
                            : 0,
                    })
                );
            }

            setIsAuthLoading(false);
        },
        [dispatch, queryClient, t]
    );

    useEffect(() => {
        const memberToken = localStorage.getItem(
            LOCAL_STORAGE_KEY.MemberstackToken
        );

        authSetup(!!memberToken);
    }, [authSetup]);

    useEffect(() => {
        const listener = memberstack.onAuthChange(
            (member: MemberstackMember | null) => authSetup(!!member)
        );

        return () => {
            listener.unsubscribe();
        };
    }, [authSetup]);

    const isLoggedIn = !!user && !isAuthLoading;
    const userHasCompany = isLoggedIn && !!user.company_entity;
    const userHasLocation = isLoggedIn && !!user.location_entity;
    const isLoggedOut = !user && !isAuthLoading;

    let routes = (
        <div className="app-loading">
            <Spinner padding="10px" />
        </div>
    );

    if (isLoggedIn) {
        routes = (
            <Layout excludeNavbarRoutes={[ROUTE.FleetCalendarFullPage]}>
                <ErrorBoundary
                    fallback={({ error, resetError }) => (
                        <ErrorComponent
                            error={error as Error}
                            resetError={resetError}
                        />
                    )}
                >
                    <Routes>
                        <Route
                            path="*"
                            element={
                                <Navigate
                                    replace
                                    to={
                                        userHasCompany && userHasLocation
                                            ? user.location_entity
                                                  ?.mt_organization
                                                ? ROUTE.Fleet
                                                : ROUTE.Orders
                                            : ROUTE.Settings
                                    }
                                />
                            }
                        />
                        {userHasCompany && userHasLocation && (
                            <>
                                <Route path={ROUTE.Orders} element={<Orders />}>
                                    <Route
                                        path={ROUTE.Orders}
                                        element={
                                            <Navigate
                                                replace
                                                to={ROUTE.NewOrder}
                                            />
                                        }
                                    />
                                    <Route
                                        path={ROUTE.NewOrder}
                                        element={<NewOrder />}
                                    />
                                    <Route
                                        path={ROUTE.ActiveOrders}
                                        element={<ActiveOrders />}
                                    />
                                    <Route
                                        path={ROUTE.FulfilledOrders}
                                        element={<FulfilledOrders />}
                                    />
                                </Route>
                                <Route path={ROUTE.Fleet} element={<Fleet />}>
                                    <Route
                                        path={ROUTE.Fleet}
                                        element={
                                            <Navigate
                                                replace
                                                to={ROUTE.ActiveFleet}
                                            />
                                        }
                                    />
                                    <Route
                                        path={ROUTE.Cockpit}
                                        element={<BigVolume />}
                                    />
                                    <Route
                                        path={ROUTE.ActiveFleet}
                                        element={<ActiveFleet />}
                                    />
                                    <Route
                                        path={ROUTE.FleetCalendar}
                                        element={<FleetCalendar />}
                                    />
                                    <Route
                                        path={ROUTE.FleetCalendarFullPage}
                                        element={
                                            <FleetCalendar
                                                fullPage
                                                autoRefresh
                                            />
                                        }
                                    />
                                    <Route
                                        path={ROUTE.FulfilledFleetTours}
                                        element={<FulfilledFleetTours />}
                                    />
                                    <Route
                                        path={ROUTE.Drivers}
                                        element={<Drivers />}
                                    />
                                    <Route
                                        path={ROUTE.Dashboard}
                                        element={<FleetDashboard />}
                                    />
                                    <Route
                                        path={ROUTE.FleetSettings}
                                        element={<FleetSettings />}
                                    />
                                    <Route
                                        path={ROUTE.FleetManualOrder}
                                        element={<FleetManualOrder />}
                                    />
                                </Route>
                            </>
                        )}
                        <Route path={ROUTE.Support} element={<Support />} />
                        <Route path={ROUTE.Settings} element={<Settings />} />
                        <Route
                            path={ROUTE.PublicOrder}
                            element={<PublicOrder shouldUseQueryParams />}
                        />
                        <Route
                            path={ROUTE.PublicOrder + "/:publicId"}
                            element={<PublicOrder />}
                        />
                        <Route
                            path={ROUTE.TrackingRedirect}
                            element={<TrackingRedirect shouldUseQueryParams />}
                        />
                        <Route
                            path={ROUTE.TrackingRedirect + "/:trackingCode"}
                            element={<TrackingRedirect />}
                        />
                        <Route path={ROUTE.Tracking} element={<Tracking />} />
                        <Route
                            path={ROUTE.ExternalOrder}
                            element={
                                <ExternalCarrierOrder shouldUseQueryParams />
                            }
                        />
                        <Route
                            path={ROUTE.ExternalOrder + "/:externalId"}
                            element={<ExternalCarrierOrder />}
                        />
                    </Routes>
                </ErrorBoundary>
            </Layout>
        );
    }

    if (isLoggedOut) {
        routes = (
            <NonAuthLayout>
                <ErrorBoundary
                    fallback={({ error, resetError }) => (
                        <ErrorComponent
                            error={error as Error}
                            resetError={resetError}
                        />
                    )}
                >
                    <Routes>
                        <Route
                            path="*"
                            element={<Navigate replace to={ROUTE.Login} />}
                        />
                        <Route path={ROUTE.Login} element={<Login />} />
                        <Route path={ROUTE.Register} element={<Register />} />
                        <Route path={ROUTE.EmailSent} element={<EmailSent />} />
                        <Route path={ROUTE.AuthCode} element={<AuthCode />} />
                        <Route path={ROUTE.Setup} element={<Setup />} />
                        <Route
                            path={ROUTE.ResetPassword}
                            element={<ResetPassword />}
                        />

                        <Route
                            path={ROUTE.PublicOrder}
                            element={<PublicOrder shouldUseQueryParams />}
                        />
                        <Route
                            path={ROUTE.PublicOrder + "/:publicId"}
                            element={<PublicOrder />}
                        />
                        <Route
                            path={ROUTE.TrackingRedirect}
                            element={<TrackingRedirect shouldUseQueryParams />}
                        />
                        <Route
                            path={ROUTE.TrackingRedirect + "/:trackingCode"}
                            element={<TrackingRedirect />}
                        />
                        <Route path={ROUTE.Tracking} element={<Tracking />} />
                        <Route
                            path={ROUTE.ExternalOrder}
                            element={
                                <ExternalCarrierOrder shouldUseQueryParams />
                            }
                        />
                        <Route
                            path={ROUTE.ExternalOrder + "/:externalId"}
                            element={<ExternalCarrierOrder />}
                        />
                    </Routes>
                </ErrorBoundary>
            </NonAuthLayout>
        );
    }

    return routes;
}

export default App;
