import { faCommentLines, faWarning } from "@fortawesome/pro-duotone-svg-icons";
import { faTag } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DraggableProvided } from "@hello-pangea/dnd";
import { isAfter, parse } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Badge from "../../../components/UI/Badge";
import Spinner from "../../../components/UI/Spinner";
import StopCardIcon from "../../../components/UI/StopCardIcon";
import { StopDraft } from "../../../shared/types/api";
import { formatEta } from "../../../shared/utility/date";
import { convertToNumberWithSpaces } from "../../../shared/utility/misc";
import {
    getStopLocationText,
    getStopStatus,
} from "../../../shared/utility/stop-draft";
import { STOP_DRAFT_TYPE } from "../../../shared/values/enums";
import "./style.scss";

export type StopCardProps = {
    // Drag and Drop
    provided?: DraggableProvided;

    //Core data
    stop: StopDraft;
    index: number;
    runningWeight?: number;
    eta?: string;
    groupedWeight?: number;
    order?: number;

    //states
    isLoading?: boolean;
    color?: string;
    groupedAmount?: number;
    hideBecauseOfGroup?: boolean;

    //actions handlers
    onHover?: (groupId: number) => void;
    onClick?: () => void;
};

function StopCard(props: StopCardProps) {
    const { t } = useTranslation();

    const warningIconStyle = {
        "--fa-primary-color": "var(--color-neutral-900)",
        "--fa-primary-opacity": "1",
        "--fa-secondary-color": "var(--color-yellow-400)",
        "--fa-secondary-opacity": "1",
    };

    const commentIconStyle = {
        "--fa-primary-color": "var(--color-pure-white)",
        "--fa-primary-opacity": "1",
        "--fa-secondary-color": "var(--color-sky-400)",
        "--fa-secondary-opacity": "1",
    };

    const etaString = useMemo(() => {
        const eta = formatEta(
            props.stop.completed_at ||
                props.stop.arrived_at ||
                props.stop.eta_internal ||
                undefined,
            {
                showTimeIfInThePast: true,
            }
        );

        return eta || props.eta;
    }, [
        props.eta,
        props.stop.arrived_at,
        props.stop.completed_at,
        props.stop.eta_internal,
    ]);

    const isLate = useMemo(() => {
        if (!props.stop.time_tooltip || !etaString) return false;

        const parsedTooltip = parse(
            props.stop.time_tooltip,
            "HH:mm",
            new Date()
        );
        const parsedEta = parse(etaString, "HH:mm", new Date());

        return isAfter(parsedEta, parsedTooltip);
    }, [props.stop.time_tooltip, etaString]);

    const { isCompleted } = useMemo(() => {
        return getStopStatus(props.stop);
    }, [props.stop]);

    const weightString = useMemo(() => {
        const weight = props.groupedWeight || props.stop.weight_kg || 0;

        if (props.runningWeight === undefined)
            return convertToNumberWithSpaces(weight, "kg");

        return `${
            props.stop.stop_type_id === STOP_DRAFT_TYPE.Pickup ? "+" : "-"
        }${weight} kg (${props.runningWeight} kg)`;
    }, [
        props.groupedWeight,
        props.runningWeight,
        props.stop.stop_type_id,
        props.stop.weight_kg,
    ]);

    return (
        <div
            className="stop-card"
            onMouseEnter={() => props.onHover?.(props.stop.group_id)}
            onMouseLeave={() => props.onHover?.(-1)}
            data-group-id={props.stop.group_id}
            data-card-id={props.stop.id}
            ref={props.provided?.innerRef}
            onClick={props.onClick}
            {...props.provided?.draggableProps}
            {...props.provided?.dragHandleProps}
            style={{
                opacity: isCompleted ? 0.5 : 1,
                display: props.hideBecauseOfGroup ? "none" : undefined,
                ...props.provided?.draggableProps.style,
            }}
        >
            {props.isLoading && (
                <div className="loading-wrapper">
                    <Spinner />
                </div>
            )}
            {!!props.groupedAmount && (
                <div className="grouped-amount">
                    <span className="text-xs">{props.groupedAmount}</span>
                </div>
            )}

            <div className="top">
                <div className="header">
                    <StopCardIcon
                        isCompleted={isCompleted}
                        label={
                            props.order?.toString() ||
                            (props.index + 1).toString()
                        }
                        color={props.color}
                    />
                    <div className="header-info">
                        <p className="type text-2xs">
                            {props.stop.stop_type_id === STOP_DRAFT_TYPE.Pickup
                                ? t("bigVolume.loading")
                                : t("bigVolume.unloading")}
                        </p>
                        <p
                            className="address text-xs"
                            title={props.stop.to_location}
                        >
                            {getStopLocationText(props.stop)}
                        </p>
                    </div>
                </div>
                <div className="eta-wrapper">
                    {props.stop.driver_note && (
                        <FontAwesomeIcon
                            icon={faCommentLines}
                            style={commentIconStyle as React.CSSProperties}
                            size="lg"
                            title={props.stop.driver_note}
                        />
                    )}
                    {props.stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff &&
                        isLate && (
                            <FontAwesomeIcon
                                icon={faWarning}
                                style={warningIconStyle as React.CSSProperties}
                                size="lg"
                                title={t("bigVolume.isLateTitle")}
                            />
                        )}
                    <p className="eta">{etaString || "-"}</p>
                </div>
            </div>
            <div className="info">
                {weightString ? (
                    <p className="text-xs">{weightString}</p>
                ) : (
                    <div />
                )}
                {props.stop.tag && (
                    <Badge
                        title={props.stop.tag}
                        variant="neutral"
                        icon={faTag}
                    />
                )}
            </div>
        </div>
    );
}

export default StopCard;
