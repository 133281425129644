import { useCallback } from "react";
import { StopFilter } from "../../shared/types/internal";
import { StopTableData } from "../../pages/BigVolume";
import { getStopLocationText } from "../../shared/utility/stop-draft";

function useStopFilter({
    activeFilter,
    stopSearch,
    excludeFilter,
}: {
    activeFilter: StopFilter;
    stopSearch: string;
    excludeFilter?: keyof StopFilter;
}) {
    const filterStops = useCallback(
        ({ stops, orderDraft }: StopTableData) => {
            let showBasedOnSearch = true;
            if (stopSearch) {
                let searchString = "";

                if (stops) {
                    searchString = `${stops.pickup.order_number} ${
                        stops.pickup.to_location
                    } ${stops.dropoff.to_location} ${getStopLocationText(
                        stops.pickup
                    )} ${getStopLocationText(stops.dropoff)} ${
                        stops.pickup.tag
                    } ${stops.pickup.weight_kg} ${
                        stops.pickup.contact?.name || ""
                    } ${stops.dropoff.contact?.name || ""}`;
                }

                if (orderDraft) {
                    searchString = `${orderDraft.origin_order_number || ""} ${
                        orderDraft.from_location || ""
                    } ${orderDraft.to_location || ""} ${orderDraft.tag || ""} ${
                        orderDraft.weight_kg || ""
                    } ${orderDraft.pickup_contact?.name || ""} ${
                        orderDraft.dropoff_contact?.name || ""
                    }`;
                }

                showBasedOnSearch = searchString
                    .toLowerCase()
                    .includes(stopSearch.toLowerCase());
            }

            let showBasedOnTag = true;
            if (activeFilter.tags.length && excludeFilter !== "tags") {
                const tag = stops?.pickup.tag || orderDraft?.tag;
                showBasedOnTag = activeFilter.tags.includes(tag || "");
            }

            let showBasedOnPickup = true;
            if (
                activeFilter.pickupCities.length &&
                excludeFilter !== "pickupCities"
            ) {
                const pickupCity =
                    stops?.pickup.city ||
                    stops?.pickup.to_location ||
                    orderDraft?.from_location;

                showBasedOnPickup = activeFilter.pickupCities.includes(
                    pickupCity || ""
                );
            }

            let showBasedOnDropoff = true;
            if (
                activeFilter.dropoffCities.length &&
                excludeFilter !== "dropoffCities"
            ) {
                const dropoffCity =
                    stops?.dropoff.city ||
                    stops?.dropoff.to_location ||
                    orderDraft?.to_location;

                showBasedOnDropoff = activeFilter.dropoffCities.includes(
                    dropoffCity || ""
                );
            }

            return (
                showBasedOnSearch &&
                showBasedOnTag &&
                showBasedOnPickup &&
                showBasedOnDropoff
            );
        },
        [
            activeFilter.dropoffCities,
            activeFilter.pickupCities,
            activeFilter.tags,
            excludeFilter,
            stopSearch,
        ]
    );

    return { filterStops };
}

export default useStopFilter;
