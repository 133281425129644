import ga4 from "react-ga4";
import { getVersion } from "../utility/misc";

const analytics = {
    init: (trackingId: string) => {
        ga4.initialize(trackingId);
    },

    sendPageview: (path: string) => {
        ga4.send({
            hitType: "pageview",
            page: path,
        });
    },

    sendVersion: () => {
        ga4.event("version", {
            value: getVersion(),
            version: getVersion(),
        });
    },

    favouriteAdded: ({ name, address }: { name: string; address: string }) => {
        ga4.event("favourite_added", {
            address,
            favourite_name: name,
        });
    },

    orderCreated: ({
        price,
        earliestPickup,
        latestDelivery,
        vehicle,
        weight,
    }: {
        price: number;
        earliestPickup: string;
        latestDelivery: string;
        vehicle: string;
        weight: number;
    }) => {
        ga4.event("order_created", {
            price,
            earliest_pickup: earliestPickup,
            latest_delivery: latestDelivery,
            vehicle,
            weight,
        });
        analytics.purchase({ price, type: "order" });
    },

    tourCreated: (createdFrom: string) => {
        ga4.event("tour_created", { created_from: createdFrom });
    },

    tourConsolidated: (createdFrom: string) => {
        ga4.event("tour_consolidated", {
            created_from: createdFrom,
        });
    },

    tourCreatedFromMap: () => {
        ga4.event("tour_created_from_map");
    },

    stopMapOpened: () => {
        ga4.event("stop_map_opened");
    },

    purchase: ({ price, type }: { price: number; type: "tour" | "order" }) => {
        ga4.event("purchase", {
            price,
            value: price,
            type,
            currency: "SEK",
        });
    },

    orderPriceSet: ({
        price,
        vehicle,
        weight,
        distance,
        earliestPickup,
        latestDelivery,
    }: {
        price: number;
        vehicle: string;
        weight: number;
        distance: number;
        earliestPickup: string;
        latestDelivery: string;
    }) => {
        ga4.event("order_price_set", {
            price,
            value: price,
            vehicle,
            weight,
            distance,
            earliest_pickup: earliestPickup,
            latest_delivery: latestDelivery,
        });
    },

    orderMultipriceSet: ({
        weight,
        distance,
    }: {
        weight: number;
        distance: number;
    }) => {
        ga4.event("order_multiprice_set", {
            weight,
            distance,
        });
    },

    fatalError: ({ error, userId }: { error: Error; userId?: number }) => {
        ga4.event("fatal_error", {
            name: error.name,
            message: error.message,
            cause: error.cause,
            fatal_error_user_id: userId,
        });
    },

    sessionError: () => {
        ga4.event("session_error", {
            path: window.location.pathname,
        });
    },

    mapPinAddress: ({ address }: { address: string }) => {
        ga4.event("map_pin_address", {
            address,
        });
    },

    smsTrackingLinkUsed: () => {
        ga4.event("sms_tracking_link_used");
    },

    dashboardCsvDownloaded: () => {
        ga4.event("dashboard_csv_downloaded");
    },
};

export default analytics;
