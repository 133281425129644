import { useLocation } from "react-router-dom";
import Banner from "../../components/widgets/Banner";
import AssistantChat from "../../containers/AssistantChat";
import Navbar from "../../containers/Navbar";
import { isEnvironment } from "../../shared/utility/misc";
import "./style.scss";

type Props = {
    children: React.ReactNode;
    excludeNavbarRoutes?: string[];
};

function Layout(props: Props) {
    const location = useLocation();

    return (
        <div className="app-wrapper">
            <Banner
                hide={!!props.excludeNavbarRoutes?.includes(location.pathname)}
            />
            {props.excludeNavbarRoutes?.includes(location.pathname) ? null : (
                <Navbar />
            )}
            <div className="content">{props.children}</div>
            {isEnvironment("staging") && <AssistantChat />}
        </div>
    );
}

export default Layout;
