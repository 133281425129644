import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { TabOption } from "../../../../shared/types/internal";
import "./style.scss";

type Props = {
    tab: TabOption;
    isActive: boolean;
    onClick: () => void;
    layoutId: string;
    style?: React.CSSProperties;
};

function TabButton(props: Props) {
    return (
        <button
            className="tab-button"
            type="button"
            style={{
                color: props.isActive
                    ? "var(--color-neutral-900)"
                    : "var(--color-neutral-600)",
                ...props.style,
            }}
            onClick={props.onClick}
        >
            {props.tab.icon && (
                <FontAwesomeIcon
                    icon={props.tab.icon}
                    size="lg"
                    style={{ zIndex: 10 }}
                    color={
                        props.isActive
                            ? "var(--color-neutral-900)"
                            : "var(--color-neutral-500)"
                    }
                />
            )}
            {props.tab.label && (
                <p
                    className="text-xs"
                    style={{
                        zIndex: 10,
                        paddingRight: props.tab.icon ? "4px" : "0",
                        color: props.isActive
                            ? "var(--color-neutral-900)"
                            : "var(--color-neutral-500)",
                    }}
                >
                    {props.tab.label}
                </p>
            )}
            {props.isActive && (
                <motion.span
                    layoutId={props.layoutId}
                    className="active-dot"
                    transition={{
                        type: "spring",
                        bounce: 0.2,
                        duration: 0.4,
                    }}
                />
            )}
        </button>
    );
}

export default TabButton;
