import {
    DragDropContext,
    Droppable,
    OnDragEndResponder,
} from "@hello-pangea/dnd";
import { useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { endOfDay, isWithinInterval, startOfDay } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ZodError } from "zod";
import { createTour } from "../../api/tours";
import ShowingAllMessage from "../../components/UI/ShowingAllMessage";
import ButtonOLD from "../../components/buttons/ButtonOLD";
import IconButton from "../../components/buttons/IconButton";
import DateInputOLD from "../../components/inputs/DateInputOLD";
import DropdownOLD from "../../components/inputs/DropdownOLD";
import alertPrompt from "../../components/widgets/alertPrompt";
import CombinedStopCard from "../../fleet-planner/cards/CombinedStopCard";
import StopDraftDraggable from "../../fleet-planner/cards/StopDraftDraggable";
import Popup from "../../hoc/Popup";
import useDrivers from "../../hooks/data/useDrivers";
import useDirections from "../../hooks/functionality/useDirections";
import usePolylinePath from "../../hooks/functionality/usePolylinePath";
import { CreateTourForm, createTourForm } from "../../schemas/form";
import analytics from "../../shared/services/ga4";
import { Driver, StopDraft } from "../../shared/types/api";
import { StopDraftsMapFilterOptions } from "../../shared/types/internal";
import { ReduxState } from "../../shared/types/redux";
import {
    dateToString,
    formatMinutes,
    stringToDate,
} from "../../shared/utility/date";
import { getDriverDisplayName } from "../../shared/utility/misc";
import {
    cardHoverHandler,
    getRunningWeight,
    getStopListOffloadDurationInMinutes,
} from "../../shared/utility/stop-draft";
import {
    API_ERROR,
    DEFAULT_CENTER,
    STOP_DRAFT_TYPE,
    STOP_MAP_ID,
} from "../../shared/values/enums";
import { GOOGLE_MAP_IDS } from "../../shared/values/google-map-ids";
import Map from "../Map";
import DriverMarkers from "../markers/DriverMarkers";
import LocationMarker from "../markers/LocationMarker";
import StopDraftMarkers from "../markers/StopDraftMarkers";
import StopDraftsMapFilter from "./StopDraftsMapFilter";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    stopDrafts: StopDraft[];
    drivers: Driver[];
    onTourCreated: () => void;
    preSelectedDate?: Date;
};

function StopDraftsMap(props: Props) {
    const { onClose, onTourCreated } = props;

    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const { settings } = useSelector((state: ReduxState) => state.fleetPlanner);

    const { drivers, refetch: refetchDrivers } = useDrivers({
        driversFromCompany:
            !!user?.company_entity?.show_location_orders &&
            !!settings.showAllDriversInCompany,
    });

    const [selectedStops, setSelectedStops] = useState<StopDraft[]>([]);
    const [highlightedStops, setHighlightedStops] = useState<
        { pickup: StopDraft; dropoff: StopDraft }[]
    >([]);

    const [focusedStops, setFocusedStops] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);
    const [hoverStops, setHoverStops] = useState<StopDraft[]>([]);
    const [filter, setFilter] = useState<StopDraftsMapFilterOptions>({
        stopTypeToShow: "both",
        date: null,
        time: null,
        showDrivers: false,
        showLocations: false,
        color: null,
        contactId: null,
        tag: null,
    });
    const [selectedDriverId, setSelectedDriverId] = useState("");
    const [tourDate, setTourDate] = useState<Date | null>(null);
    const [isUnhandledExpanded, setIsUnhandledExpanded] = useState(false);
    const [mapPanTo, setMapPanTo] = useState<{
        lat: number;
        lng: number;
    } | null>(null);
    const [mapPanBounds, setMapPanBounds] = useState<
        {
            lat: number;
            lng: number;
        }[]
    >([]);

    const { pathDurationInMinutes } = useDirections({
        hide: !selectedStops.length,
        path: selectedStops
            .filter((sd) => sd.lat && sd.lng)
            .map((stop) => ({
                lat: +stop.lat!,
                lng: +stop.lng!,
            })),
    });

    usePolylinePath({
        hide: !focusedStops,
        path: focusedStops
            ? [
                  {
                      lat: +focusedStops.pickup.lat!,
                      lng: +focusedStops.pickup.lng!,
                  },
                  {
                      lat: +focusedStops.dropoff.lat!,
                      lng: +focusedStops.dropoff.lng!,
                  },
              ]
            : [],
    });

    useEffect(() => {
        setFilter((state) => ({
            ...state,
            date: { from: props.preSelectedDate || null, to: null },
        }));
    }, [props.preSelectedDate]);

    useEffect(() => {
        if (props.showPopup) {
            analytics.stopMapOpened();
        }
    }, [props.showPopup]);

    const highlightedStopIndex = useMemo(
        () =>
            highlightedStops.findIndex(
                (sd) =>
                    sd.pickup.group_id === (focusedStops?.pickup.group_id || -1)
            ),
        [focusedStops, highlightedStops]
    );

    const closeHandler = useCallback(
        async (manualClose: boolean) => {
            if (selectedStops.length > 0 && manualClose) {
                const accepted = await alertPrompt({
                    title: t("popup.stopDraft.unsavedTourTitle"),
                    message: t("popup.stopDraft.unsavedTourMessage"),
                });

                if (!accepted) {
                    return;
                }
            }

            setHighlightedStops([]);
            setFocusedStops(null);
            setTourDate(null);
            setSelectedStops([]);
            setSelectedDriverId("");
            onClose();
        },
        [onClose, selectedStops.length, t]
    );

    const selectStopsHandler = useCallback((stops: StopDraft[]) => {
        setSelectedStops((state) => {
            const newState = state.concat(stops);
            return newState;
        });
    }, []);

    const onDragEnd = useCallback<OnDragEndResponder>(
        (result) => {
            if (!result.destination) return;
            const { source, destination } = result;

            setSelectedStops((state) => {
                const newSelectedStops = [...state];

                const [removed] = newSelectedStops.splice(source.index, 1);
                newSelectedStops.splice(destination.index, 0, removed);

                const groupedStopIndex = newSelectedStops.findIndex(
                    (sd) =>
                        sd.group_id === removed?.group_id &&
                        sd.id !== removed.id
                );

                if (
                    removed.stop_type_id === STOP_DRAFT_TYPE.Pickup &&
                    destination.index > groupedStopIndex
                ) {
                    toast.error(t("errorMessage.invalidMovePickup"));
                    return state;
                } else if (
                    removed.stop_type_id === STOP_DRAFT_TYPE.Dropoff &&
                    destination.index < groupedStopIndex
                ) {
                    toast.error(t("errorMessage.invalidMoveDropoff"));
                    return state;
                }

                return newSelectedStops;
            });
        },
        [t]
    );

    const sortTourPickupsFirst = useCallback(() => {
        setSelectedStops((state) => {
            const pickups = state.filter(
                (stop) => stop.stop_type_id === STOP_DRAFT_TYPE.Pickup
            );

            const dropoffs = state.filter(
                (stop) => stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff
            );

            const sortedStops = pickups.concat(dropoffs);

            return sortedStops;
        });
    }, []);

    const deselectStopHandler = useCallback((stop: StopDraft) => {
        setSelectedStops((state) =>
            state.filter((sd) => stop?.group_id !== sd.group_id)
        );
    }, []);

    const filterStopMarkers = useCallback(
        (stop: StopDraft) => {
            let showBasedOnDate = false;
            if (filter.date) {
                if (!!filter.date.from && !!filter.date.to) {
                    showBasedOnDate = stop.date_tooltip
                        ? isWithinInterval(stringToDate(stop.date_tooltip)!, {
                              start: startOfDay(filter.date.from),
                              end: endOfDay(filter.date.to),
                          })
                        : true;
                } else if (!filter.date.to && !!filter.date.from) {
                    showBasedOnDate = stop.date_tooltip
                        ? dateToString(filter.date.from) === stop.date_tooltip
                        : true;
                } else {
                    showBasedOnDate = true;
                }
            } else {
                showBasedOnDate = true;
            }

            let showBasedOnTime = false;
            if (filter.time) {
                showBasedOnTime = isWithinInterval(
                    new Date(`2000-01-01T${stop.time_tooltip}`),
                    {
                        start: new Date(
                            `2000-01-01T${filter.time.from || "00:00"}`
                        ),
                        end: new Date(
                            `2000-01-01T${filter.time.to || "23:59"}`
                        ),
                    }
                );
            } else {
                showBasedOnTime = true;
            }

            let showBasedOnType = false;
            if (filter.stopTypeToShow === "both") {
                showBasedOnType = true;
            }
            if (filter.stopTypeToShow === "pickup") {
                showBasedOnType = stop.stop_type_id === STOP_DRAFT_TYPE.Pickup;
            }
            if (filter.stopTypeToShow === "dropoff") {
                showBasedOnType = stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff;
            }

            let showBasedOnColor = false;
            if (filter.color) {
                if (filter.color === "without") {
                    showBasedOnColor = !stop.color;
                } else {
                    showBasedOnColor = stop.color === filter.color;
                }
            } else {
                showBasedOnColor = true;
            }

            let showBasedOnContact = false;
            if (filter.contactId) {
                showBasedOnContact = stop.contact?.id === filter.contactId;
            } else {
                showBasedOnContact = true;
            }

            let showBasedOnTag = false;
            if (filter.tag) {
                if (filter.tag === "without") {
                    showBasedOnTag = !stop.tag;
                } else {
                    showBasedOnTag = stop.tag === filter.tag;
                }
            } else {
                showBasedOnTag = true;
            }

            return (
                showBasedOnType &&
                showBasedOnDate &&
                showBasedOnTime &&
                showBasedOnColor &&
                showBasedOnContact &&
                showBasedOnTag
            );
        },
        [filter]
    );

    const { mutate: createTourHandler, isPending: isCreatingTour } =
        useMutation({
            mutationFn: async (data: CreateTourForm) => {
                createTourForm.parse(data);
                const res = await createTour({
                    date: data.date,
                    time: data.time,
                    preferred_driver_id: data.preferredDriverId,
                    stop_draft_ids: data.stopDraftIds,
                });

                if (!!res.data.excluded_stop_draft_ids.length) {
                    toast.error(
                        t("errorMessage.tourCreationSuccessExcludedStops")
                    );
                }
            },
            onSuccess: () => {
                analytics.tourCreated("stop-drafts-map");
                analytics.tourCreatedFromMap();
                toast.success(t("successMessage.tourCreated"));
                onTourCreated();
                closeHandler(false);
            },
            onError: (error) => {
                if (error instanceof ZodError) {
                    toast.error(t(error.issues[0].message));
                    return;
                }

                if (
                    isAxiosError<{ detail: string; error_token: string }>(error)
                ) {
                    if (
                        error.response?.data.detail === API_ERROR.InvalidStops
                    ) {
                        toast.error(t("errorMessage.tourCreationInvalidStop"));
                        return;
                    }

                    if (error.response?.data.error_token) {
                        toast.error(t(error.response?.data.error_token));
                        return;
                    }
                }

                toast.error(t("errorMessage.tourCreationFailed"));
            },
        });

    const focusStopsHandler = useCallback(
        (stop: StopDraft) => {
            const pickup = props.stopDrafts.find(
                (sd) =>
                    sd.group_id === stop.group_id &&
                    sd.stop_type_id === STOP_DRAFT_TYPE.Pickup
            );

            const dropoff = props.stopDrafts.find(
                (sd) =>
                    sd.group_id === stop.group_id &&
                    sd.stop_type_id === STOP_DRAFT_TYPE.Dropoff
            );

            if (!pickup || !dropoff) return;

            setFocusedStops({ pickup, dropoff });
        },
        [props.stopDrafts]
    );

    const stopMarkerClickHandler = useCallback(
        (stop: StopDraft) => {
            const pickup = props.stopDrafts.find(
                (sd) =>
                    sd.group_id === stop.group_id &&
                    sd.stop_type_id === STOP_DRAFT_TYPE.Pickup
            );

            const dropoff = props.stopDrafts.find(
                (sd) =>
                    sd.group_id === stop.group_id &&
                    sd.stop_type_id === STOP_DRAFT_TYPE.Dropoff
            );

            if (!pickup || !dropoff) return;

            const selectedStopIds = selectedStops.map((sd) => sd.id);

            setFocusedStops((state) => {
                if (
                    state &&
                    state.pickup.group_id === pickup.group_id &&
                    !(
                        selectedStopIds.includes(pickup.id) ||
                        selectedStopIds.includes(dropoff.id)
                    )
                ) {
                    selectStopsHandler([pickup, dropoff]);
                    return state;
                }

                return { pickup, dropoff };
            });
        },
        [props.stopDrafts, selectStopsHandler, selectedStops]
    );

    const stopCardHoverHandler = useCallback(
        ({
            groupId,
            highlightMarkers,
        }: {
            groupId: number;
            highlightMarkers?: boolean;
        }) => {
            if (highlightMarkers) {
                const allStopsInGroup = props.stopDrafts.filter(
                    (sd) => sd.group_id === groupId
                );
                setHoverStops(allStopsInGroup);
            }
        },
        [props.stopDrafts]
    );

    const filteredStops = useMemo(() => {
        const newFilteredStops: { pickup: StopDraft; dropoff: StopDraft }[] =
            [];

        const stopsFromFilter = props.stopDrafts.filter(filterStopMarkers);

        const groupIds = Array.from(
            new Set(stopsFromFilter.map((sd) => sd.group_id))
        );

        const pickups = props.stopDrafts.filter(
            (sd) =>
                sd.stop_type_id === STOP_DRAFT_TYPE.Pickup &&
                groupIds.includes(sd.group_id)
        );

        for (let i = 0; i < pickups.length; i++) {
            const pickup = pickups[i];

            const dropoff = props.stopDrafts.find(
                (stop) =>
                    stop.group_id === pickup.group_id &&
                    stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff
            );

            if (!dropoff) continue;

            newFilteredStops.push({ pickup, dropoff });
        }

        return newFilteredStops;
    }, [props.stopDrafts, filterStopMarkers]);

    const tourDuration = useMemo(() => {
        if (!pathDurationInMinutes) return null;

        const offloadingTime =
            getStopListOffloadDurationInMinutes(selectedStops);
        const totalDuration = pathDurationInMinutes + offloadingTime;
        return formatMinutes(Math.ceil(totalDuration));
    }, [pathDurationInMinutes, selectedStops]);

    const stopsForMap = useMemo(
        () => props.stopDrafts.filter(filterStopMarkers),
        [filterStopMarkers, props.stopDrafts]
    );

    const selectedStopIds = useMemo(
        () => selectedStops.map((sd) => sd.id),
        [selectedStops]
    );

    const hoverStopIds = useMemo(
        () => hoverStops.map((s) => s.id),
        [hoverStops]
    );

    const { peakWeight, runningWeights } = useMemo(() => {
        return getRunningWeight(selectedStops);
    }, [selectedStops]);

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={() => closeHandler(true)}
            dontCloseOnOutsideClick
            className="stop-drafts-map"
            width={selectedStops.length ? "30vw" : "auto"}
            overlayComponent={
                <div className="stop-drafts-map-content">
                    <div
                        className="unhandled-stops"
                        style={{
                            width: isUnhandledExpanded ? "500px" : "auto",
                        }}
                    >
                        <div className="top">
                            {isUnhandledExpanded && (
                                <span className="text-sm">
                                    {t("fleetPlanner.unhandledTitle")}
                                </span>
                            )}
                            <IconButton
                                short
                                icon={
                                    isUnhandledExpanded
                                        ? "chevron-left"
                                        : "chevron-right"
                                }
                                onClick={() =>
                                    setIsUnhandledExpanded((state) => !state)
                                }
                            />
                        </div>
                        {isUnhandledExpanded ? (
                            filteredStops.length === 0 ? (
                                <ShowingAllMessage
                                    text={t("fleetPlanner.noStopsForFilter")}
                                />
                            ) : (
                                filteredStops.map((stops) => (
                                    <CombinedStopCard
                                        key={stops.pickup.id}
                                        stops={stops}
                                        onHover={(groupId) =>
                                            stopCardHoverHandler({
                                                groupId,
                                                highlightMarkers: true,
                                            })
                                        }
                                        onClick={() => {
                                            setFocusedStops(stops);
                                            setHighlightedStops([stops]);
                                            if (
                                                stops.pickup.lat &&
                                                stops.pickup.lng &&
                                                stops.dropoff.lat &&
                                                stops.dropoff.lng
                                            ) {
                                                setMapPanBounds([
                                                    {
                                                        lat: +stops.pickup.lat,
                                                        lng: +stops.pickup.lng,
                                                    },
                                                    {
                                                        lat: +stops.dropoff.lat,
                                                        lng: +stops.dropoff.lng,
                                                    },
                                                ]);
                                            }
                                        }}
                                    />
                                ))
                            )
                        ) : null}
                    </div>
                    <div className="map-wrapper">
                        <StopDraftsMapFilter
                            filter={filter}
                            onChange={setFilter}
                            onUpdateDrivers={refetchDrivers}
                            stops={props.stopDrafts}
                        />
                        <Map
                            mapId={GOOGLE_MAP_IDS.UnhandledStopsMap}
                            defaultCenter={
                                user?.location_entity?.lat &&
                                user?.location_entity?.lng
                                    ? {
                                          lat: user.location_entity.lat,
                                          lng: user.location_entity.lng,
                                      }
                                    : DEFAULT_CENTER
                            }
                            defaultZoom={12}
                            onClick={() => {
                                setHighlightedStops([]);
                                setFocusedStops(null);
                                setMapPanTo(null);
                                setMapPanBounds([]);
                            }}
                            showTrafficToggle
                        >
                            <StopDraftMarkers
                                stopDrafts={stopsForMap}
                                onSelectStops={setHighlightedStops}
                                onMarkerClick={stopMarkerClickHandler}
                                highligtedStops={focusedStops || null}
                                hoverStopIds={hoverStopIds}
                                selectedStopIds={selectedStopIds}
                                panTo={mapPanTo}
                                panBounds={mapPanBounds}
                            />
                            {user?.location_entity && filter.showLocations && (
                                <LocationMarker
                                    key={user.location_entity.id}
                                    location={user.location_entity}
                                />
                            )}
                            {filter.showLocations &&
                                user?.company_entity?.locations
                                    .filter(
                                        (l) => l.id !== user.location_entity?.id
                                    )
                                    .map((location) => (
                                        <LocationMarker
                                            key={location.id}
                                            location={location}
                                        />
                                    ))}
                            {filter.showDrivers && drivers && (
                                <DriverMarkers drivers={drivers} />
                            )}
                        </Map>
                        {focusedStops && (
                            <div className="info-card">
                                <CombinedStopCard stops={focusedStops} />
                                <div className="bottom">
                                    {highlightedStops.length > 1 && (
                                        <div className="navigation">
                                            <IconButton
                                                icon="chevron-left"
                                                short
                                                onClick={() => {
                                                    setFocusedStops((state) => {
                                                        if (!state) return null;
                                                        const isFirst =
                                                            highlightedStopIndex ===
                                                            0;
                                                        return (
                                                            highlightedStops[
                                                                isFirst
                                                                    ? highlightedStops.length -
                                                                      1
                                                                    : highlightedStopIndex -
                                                                      1
                                                            ] || null
                                                        );
                                                    });
                                                }}
                                            />
                                            <span>
                                                {highlightedStopIndex + 1}/
                                                {highlightedStops.length}
                                            </span>
                                            <IconButton
                                                icon="chevron-right"
                                                short
                                                onClick={() => {
                                                    setFocusedStops((state) => {
                                                        if (!state) return null;
                                                        const isLast =
                                                            highlightedStopIndex ===
                                                            highlightedStops.length -
                                                                1;
                                                        return (
                                                            highlightedStops[
                                                                isLast
                                                                    ? 0
                                                                    : highlightedStopIndex +
                                                                      1
                                                            ] || null
                                                        );
                                                    });
                                                }}
                                            />
                                        </div>
                                    )}
                                    {selectedStops.some((sd) =>
                                        [
                                            focusedStops.pickup.id,
                                            focusedStops.dropoff.id,
                                        ].includes(sd.id)
                                    ) ? (
                                        <ButtonOLD
                                            label={t(
                                                "fleetPlanner.deselectStop"
                                            )}
                                            short
                                            color="secondary"
                                            onClick={() =>
                                                deselectStopHandler(
                                                    focusedStops.pickup
                                                )
                                            }
                                        />
                                    ) : (
                                        <ButtonOLD
                                            label={t("fleetPlanner.selectStop")}
                                            short
                                            color="secondary"
                                            onClick={() =>
                                                selectStopsHandler([
                                                    focusedStops.pickup,
                                                    focusedStops.dropoff,
                                                ])
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            }
        >
            {!!selectedStops.length && (
                <div className="stop-drafts-map-controls">
                    <ButtonOLD
                        color="secondary"
                        label={t("createTour.sortByType")}
                        onClick={sortTourPickupsFirst}
                        width="250px"
                    />
                </div>
            )}
            {!!selectedStops.length && tourDuration && (
                <div className="stop-drafts-map-info">
                    <span>
                        {t("fleetPlanner.estimatedTime")} {tourDuration}
                    </span>
                    <span>
                        {t("fleetPlanner.maxRunningWeight")} {peakWeight}
                        kg
                    </span>
                </div>
            )}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={STOP_MAP_ID}>
                    {(provided) => (
                        <div
                            className="stop-drafts-map-column"
                            ref={provided.innerRef}
                        >
                            {selectedStops.map((stopDraft, i) => (
                                <StopDraftDraggable
                                    key={stopDraft.id}
                                    stopDraft={stopDraft}
                                    index={i}
                                    showPosition
                                    runningWeight={runningWeights[i]}
                                    onHover={(groupId) =>
                                        cardHoverHandler({
                                            groupId,
                                            columnSelector:
                                                ".stop-drafts-map .stop-drafts-map-column",
                                        })
                                    }
                                    modalContent={[
                                        {
                                            title: t("fleetPlanner.deselect"),
                                            onClick: () =>
                                                deselectStopHandler(stopDraft),
                                        },
                                    ]}
                                    onClick={() => {
                                        focusStopsHandler(stopDraft);
                                        setHighlightedStops([]);
                                        if (stopDraft.lat && stopDraft.lng) {
                                            setMapPanTo({
                                                lat: +stopDraft.lat,
                                                lng: +stopDraft.lng,
                                            });
                                        }
                                    }}
                                />
                            ))}
                            {provided.placeholder}
                            {!!selectedStops.length && (
                                <div className="bottom">
                                    <div className="inputs">
                                        <DateInputOLD
                                            value={tourDate}
                                            onChange={setTourDate}
                                            label={t("createTour.dateLabel")}
                                            minDateDays={0}
                                            ignoreUnselectableDates
                                        />
                                        <DropdownOLD
                                            value={selectedDriverId}
                                            onSelect={({ value }) =>
                                                setSelectedDriverId(value)
                                            }
                                            options={props.drivers
                                                .filter((d) => d.mt_driver_id)
                                                .map((d) => ({
                                                    label: getDriverDisplayName(
                                                        d
                                                    ),
                                                    value: d.mt_driver_id || "",
                                                }))}
                                            menuPlacement="top"
                                            width="100%"
                                            label={t("createTour.driverLabel")}
                                        />
                                    </div>

                                    <ButtonOLD
                                        label={t("createTour.createTourLabel")}
                                        onClick={() =>
                                            createTourHandler({
                                                preferredDriverId:
                                                    selectedDriverId,
                                                stopDraftIds: selectedStops.map(
                                                    (sd) => sd.id
                                                ),
                                                date: dateToString(tourDate),
                                                time: "06:00",
                                            })
                                        }
                                        isLoading={isCreatingTour}
                                        color="secondary"
                                        disabled={
                                            !selectedDriverId || !tourDate
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Popup>
    );
}

export default StopDraftsMap;
