import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { APIProvider } from "@vis.gl/react-google-maps";
import { Chart } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { sv } from "date-fns/locale";
import i18next from "i18next";
import I18NextLocizeBackend from "i18next-locize-backend";
import locizeLastUsed from "locize-lastused";
import { registerLocale } from "react-datepicker";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { hotjar } from "react-hotjar";
import { initReactI18next } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import environment from "./environment";
import { getLocale, isEnvironment } from "./shared/utility/misc";
import { LOCAL_STORAGE_KEY } from "./shared/values/enums";
import store from "./store";
import "./styles/WavesEffect/waves.css";
import "./styles/WavesEffect/waves.js";
import "./styles/global.scss";
import "./styles/normalize.scss";
import "./styles/variables.scss";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            retry: (failureCount) => failureCount < 6,
        },
    },
});

Sentry.init({
    dsn: "https://7f5c25c3524eea5ed8743a16ad1bd32b@o4504293342248960.ingest.sentry.io/4506852101062656",
    environment: environment.environment,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: process.env.NODE_ENV !== "development",
});

const locizeTextsThatCanBeMissing = [
    "general.banner",
    "general.bannerLinkLabel",
    "general.bannerLink",
];

i18next
    .use(I18NextLocizeBackend)
    .use(initReactI18next)
    .use(locizeLastUsed)
    .init({
        backend: {
            projectId: environment.locizeProjectId,
            apiKey: environment.locizeApiKey,
            referenceLng: "en",
        },
        saveMissing: true,
        lng: getLocale(),
        fallbackLng: "en",
        ns: ["prod", "sms"],
        parseMissingKeyHandler: (key) => {
            if (locizeTextsThatCanBeMissing.includes(key)) return null;
            return key;
        },
        locizeLastUsed: {
            projectId: environment.locizeProjectId,
            apiKey: environment.locizeApiKey,
            allowedHosts: [
                "localhost",
                "connect.alrik.com",
                "connect-staging.alrik.com",
            ],
        },
    });

i18next.on("languageChanged", (lng) => {
    document.documentElement.lang = lng;
    localStorage.setItem(LOCAL_STORAGE_KEY.UserLocale, lng);
});

// for react-datepicker
registerLocale("sv", sv);

Chart.register(ChartDataLabels);

if (isEnvironment("production")) {
    const HOTJAR_ID = 3484208;
    const HOTJAR_SNIPPET_VERSION = 6;

    hotjar.initialize({ id: HOTJAR_ID, sv: HOTJAR_SNIPPET_VERSION });
}

root.render(
    <APIProvider
        apiKey={environment.googlePlaceApiKey}
        libraries={["geocoding", "places"]}
    >
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                    <Toaster
                        position="bottom-center"
                        toastOptions={{
                            style: {
                                padding: "10px 5px",
                            },
                        }}
                    />
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    </APIProvider>
);
