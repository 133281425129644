import { isSameDay, setDay } from "date-fns";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import DateInput from "../DateInput";
import Tabs from "../../buttons/Tabs";

import "./style.scss";

type Props = {
    value: Date | null;
    onChange: (value: Date) => void;
};

function WeekDaySelector(props: Props) {
    const { onChange } = props;
    const { t } = useTranslation();
    const weekDay = props.value ? props.value.getDay() : null;

    const weekDays = [
        { value: "1", label: t("general.weekdays.monday") },
        { value: "2", label: t("general.weekdays.tuesday") },
        { value: "3", label: t("general.weekdays.wednesday") },
        { value: "4", label: t("general.weekdays.thursday") },
        { value: "5", label: t("general.weekdays.friday") },
        { value: "6", label: t("general.weekdays.saturday") },
        { value: "0", label: t("general.weekdays.sunday") },
    ];

    const changeHandler = useCallback(
        (date: Date) => {
            if (props.value && isSameDay(date, props.value)) return;

            onChange(date);
        },
        [onChange, props.value]
    );

    return (
        <div className="week-day-selector">
            <DateInput
                value={props.value}
                onChange={(value) => {
                    if (value) changeHandler(value);
                }}
                showAllDates
                ignoreUnselectableDates
            />
            <p
                className="text-xs"
                style={{ color: "var(--color-neutral-400)" }}
            >
                {t("general.or")}
            </p>
            <Tabs
                tabs={weekDays}
                activeTabValue={weekDay?.toString() || "0"}
                onTabChange={({ value }) =>
                    changeHandler(
                        setDay(props.value || new Date(), +value, {
                            weekStartsOn: 1,
                        })
                    )
                }
            />
        </div>
    );
}

export default WeekDaySelector;
