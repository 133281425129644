import { addDays, startOfTomorrow } from "date-fns";
import { dateToString } from "../utility/date";

const today = new Date();
const tomorrow = startOfTomorrow();
const todayPlus2 = addDays(today, 2);
const todayPlus3 = addDays(today, 3);

const generateRandomOrderNumber = () => Math.floor(Math.random() * 1000000);

export const SWE_DEMO_ORDERS = [
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Hornsgatan 45, Stockholm",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",
        cargoContent: "Ett paket. Parkera vid ingång B.",
        weight: 300,
        tag: "Express",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Vintergatan 21, 172 69 Sundbyberg, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 2000,
        tag: "Under dagen",
        cargoContent: "En pall. Taklyft.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Midgårdsvägen 9, 136 38 Handen, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(today),
        time: "13:00",
        weight: 1500,
        tag: "Under dagen",
        cargoContent: "Två paket och en pall.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Vasagatan 13, 761 31 Norrtälje, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(today),
        time: "15:00",
        weight: 760,
        tag: "Morgonpass",
        cargoContent: "Två pallar.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Tingvallavägen 28C, 195 35 Märsta, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(tomorrow),
        time: "12:00",
        weight: 3000,
        tag: "Express",
        cargoContent: "Ett paket. Parkera vid ingång B.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Märstavägen 20, 193 40 Sigtuna, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(tomorrow),
        time: "14:00",
        weight: 400,
        tag: "Under dagen",
        cargoContent: "En pall. Taklyft.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Vasavägen 250A, 192 69 Sollentuna, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(tomorrow),
        time: "13:00",
        weight: 300,
        tag: "Under dagen",
        cargoContent: "Två paket och en pall.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Snösätravägen, 124 59 Bandhagen, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 2000,
        tag: "Morgonpass",
        cargoContent: "Två pallar.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Dalgränd 6, 135 40 Tyresö, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(todayPlus2),
        time: "12:00",
        weight: 1500,
        tag: "Express",
        cargoContent: "Ett paket. Parkera vid ingång B.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Buskuddsvägen 16, 185 95 Vaxholm, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(todayPlus2),
        time: "14:00",
        weight: 760,
        tag: "Under dagen",
        cargoContent: "En pall. Taklyft.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Vikingshillsvägen 35, 132 37 Saltsjö-boo, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(todayPlus2),
        time: "13:00",
        weight: 3000,
        tag: "Under dagen",
        cargoContent: "Två paket och en pall.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Storsvängen 151, 129 44 Hägersten, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 400,
        tag: "Morgonpass",
        cargoContent: "Två pallar.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Tomtbergavägen 115, 145 75 Norsborg, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 300,
        tag: "Express",
        cargoContent: "Två pallar.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Gårdsfogdevägen 18A, 168 67 Bromma, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(todayPlus3),
        time: "12:00",
        weight: 2000,
        tag: "Under dagen",
        cargoContent: "Ett paket. Parkera vid ingång B.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Äventyrsparken, Kalvhagsvägen 1, 181 31 Lidingö, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(todayPlus3),
        time: "14:00",
        weight: 1500,
        tag: "Under dagen",
        cargoContent: "En pall. Taklyft.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Vasavägen 37, 181 42 Lidingö, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(todayPlus3),
        time: "13:00",
        weight: 760,
        tag: "Morgonpass",
        cargoContent: "Två paket och en pall.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Åkargränd 3, 149 30 Nynäshamn, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(todayPlus3),
        time: "15:00",
        weight: 3000,
        tag: "Express",
        cargoContent: "Två pallar.",
    },
    {
        from: "Alrik Technologies, Storgatan, Stockholm",
        to: "Nybrogatan 79B, 114 41 Stockholm, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(todayPlus3),
        time: "15:00",
        weight: 400,
        tag: "Under dagen",
        cargoContent: "Två pallar.",
    },
];

export const SWE_BIG_VOLUME_ORDERS = [
    ...SWE_DEMO_ORDERS.slice(0, 8),
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Tankebyggarbacken 40, 127 33 Skärholmen, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",
        cargoContent: "Ett paket. Parkera vid ingång B.",
        weight: 300,
        tag: "Express",
    },
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Kungssätravägen 40, 127 37 Skärholmen, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 2000,
        tag: "Under dagen",
        cargoContent: "En pall. Taklyft.",
    },
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Storhagsvägen 46, 125 54 Älvsjö, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(today),
        time: "13:00",
        weight: 1500,
        tag: "Under dagen",
        cargoContent: "Två paket och en pall.",
    },
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Talldalsvägen 17, 141 69 Huddinge, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(today),
        time: "15:00",
        weight: 760,
        tag: "Morgonpass",
        cargoContent: "Två pallar.",
    },
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Skolvägen 26, 141 44 Huddinge, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(tomorrow),
        time: "12:00",
        weight: 3000,
        tag: "Express",
        cargoContent: "Ett paket. Parkera vid ingång B.",
    },
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Hörselslingan 8, 141 52 Huddinge, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(tomorrow),
        time: "14:00",
        weight: 400,
        tag: "Under dagen",
        cargoContent: "En pall. Taklyft.",
    },
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Flottiljvägen 16, 146 38 Tullinge, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(tomorrow),
        time: "13:00",
        weight: 300,
        tag: "Under dagen",
        cargoContent: "Två paket och en pall.",
    },
    {
        from: "Elektravägen 22, 126 30 Hägersten, Sverige",
        to: "Tunavägen 34, 147 42 Tumba, Sverige",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 2000,
        tag: "Morgonpass",
        cargoContent: "Två pallar.",
    },
];

export const GB_DEMO_ORDERS = [
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "Carburton St, London W1W 5EE, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",
        weight: 300,
        tag: "Express",
        cargoContent: "Two pallets of plaster.",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "51 High St N, London E6 1HZ, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 2000,
        tag: "During the day",
        cargoContent: "One package of wood.",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "Whalebone Ln N, Romford RM6 6SB, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(today),
        time: "13:00",
        weight: 1500,
        tag: "Morning",
        cargoContent: "Two packages of glue joint.",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "18 Heathway, Woodford, Woodford Green IG8 7RG, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(today),
        time: "15:00",
        weight: 760,
        tag: "Afternoon",
        cargoContent: "Two pallets of plaster.",
    },
    {
        from: "Southey Rd, London SW19 1NU, United Kingdom",
        to: "45 Romford Road, London E15 4LY, UK",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",
        weight: 3000,
        tag: "Morning",
        cargoContent: "One package of wood.",
    },
    {
        from: "5 Stable St, London N1C 4AB, United Kingdom",
        to: "45 Romford Road, London E15 4LY, UK",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 400,
        tag: "During the day",
        cargoContent: "Two packages of glue joint.",
    },
    {
        from: "194 Southgate Rd, London N1 3HT, United Kingdom",
        to: "26 Blackhorse Rd, London E17 7BE, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(today),
        time: "13:00",
        weight: 300,
        tag: "Morning",
        cargoContent: "Two pallets of plaster.",
    },
    {
        from: "Beigel Bake Brick Lane Bakery, Brick Lane, London E1 6SB, UK",
        to: "Severndroog Castle, London SE18 3RT, UK",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(today),
        time: "15:00",
        weight: 2000,
        tag: "Afternoon",
        cargoContent: "One package of wood.",
    },
    {
        from: "1C Portland Pl, London W1B 1JA, United Kingdom",
        to: "119 Consort Rd, London SE15 3RU, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",
        weight: 1500,
        tag: "Morning",
        cargoContent: "Two packages of glue joint.",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "Hertford House, Manchester Square, London W1U 3BN, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 760,
        tag: "During the day",
        cargoContent: "Two pallets of plaster.",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "72 Honor Oak Park, London SE23 1DY, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(tomorrow),
        time: "13:00",
        weight: 3000,
        tag: "Morning",
        cargoContent: "One package of wood.",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "Warwick Square, Pimlico, London SW1V 2AD, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(tomorrow),
        time: "15:00",
        weight: 400,
        tag: "Afternoon",
        cargoContent: "Two packages of glue joint.",
    },
    {
        from: "52 Cambridge St, Pimlico, London SW1V 4JD, United Kingdom",
        to: "45 Romford Road, London E15 4LY, UK",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(tomorrow),
        time: "13:00",
        weight: 300,
        tag: "Morning",
        cargoContent: "Two pallets of plaster.",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "Drayton Park, London N5 1NT, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 2000,
        tag: "During the day",
        cargoContent: "One package of wood.",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "510C St Stephen's Rd, Bow, London E3 5ES, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(todayPlus2),
        time: "12:00",
        weight: 1500,
        tag: "Morning",
        cargoContent: "Two packages of glue joint.",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "51 Grove Rd., Bow, London E3 4PE, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(todayPlus2),
        time: "14:00",
        weight: 760,
        tag: "Afternoon",
        cargoContent: "Two pallets of plaster.",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "185 Lower Rd, London SE16 2LW, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(todayPlus2),
        time: "13:00",
        weight: 3000,
        tag: "Morning",
        cargoContent: "One package of wood.",
    },
    {
        from: "45 Romford Road, London E15 4LY, UK",
        to: "9 Priory Park, Blackheath, London SE3 9UY, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 400,
        tag: "During the day",
        cargoContent: "Two packages of glue joint.",
    },
];

export const DK_DEMO_ORDERS = [
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Copenhagen Airport (CPH), Lufthavnsboulevarden 6, 2770 Kastrup, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",
        weight: 300,
        tag: "Express",
        cargoContent: "Two pallets of plaster.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Copenhagen Central Station, Bernstorffsgade, København, Danmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 2000,
        tag: "During the day",
        cargoContent: "One package of wood.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Whalebone Ln N, Romford RM6 6SB, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(today),
        time: "13:00",
        weight: 1500,
        tag: "Morning",
        cargoContent: "Two packages of glue joint.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Alhambravej 11, 1826 Frederiksberg, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(today),
        time: "15:00",
        weight: 760,
        tag: "Afternoon",
        cargoContent: "Two pallets of plaster.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Marienborg Alle 60, 2860 Søborg, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",
        weight: 3000,
        tag: "Morning",
        cargoContent: "One package of wood.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Roskildevej 173, 2620 Albertslund, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 400,
        tag: "During the day",
        cargoContent: "Two packages of glue joint.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Park Allé 87, 2605 Brøndby, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(today),
        time: "13:00",
        weight: 300,
        tag: "Morning",
        cargoContent: "Two pallets of plaster.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Catherine Boothsvej 73, 2650 Hvidovre, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(today),
        time: "15:00",
        weight: 2000,
        tag: "Afternoon",
        cargoContent: "One package of wood.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Flensborggade 7, 1669 København, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(today),
        time: "12:00",
        weight: 1500,
        tag: "Morning",
        cargoContent: "Two packages of glue joint.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Perikumhaven 69A, 2730 Herlev, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(today),
        time: "14:00",
        weight: 760,
        tag: "During the day",
        cargoContent: "Two pallets of plaster.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Ejbydalsvej 15, 2600 Glostrup, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(tomorrow),
        time: "13:00",
        weight: 3000,
        tag: "Morning",
        cargoContent: "One package of wood.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Arne Jacobsens Allé 22, 2300 København, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(tomorrow),
        time: "15:00",
        weight: 400,
        tag: "Afternoon",
        cargoContent: "Two packages of glue joint.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Markvænget 12, 2791 Dragør, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(tomorrow),
        time: "13:00",
        weight: 300,
        tag: "Morning",
        cargoContent: "Two pallets of plaster.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Drayton Park, London N5 1NT, United Kingdom",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 2000,
        tag: "During the day",
        cargoContent: "One package of wood.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Bagsværd Hovedgade 228, 2880 Bagsværd, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 2,
        date: dateToString(todayPlus2),
        time: "12:00",
        weight: 1500,
        tag: "Morning",
        cargoContent: "Two packages of glue joint.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Sekskanten A 9, 2630 Taastrup, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 3,
        date: dateToString(todayPlus2),
        time: "14:00",
        weight: 760,
        tag: "Afternoon",
        cargoContent: "Two pallets of plaster.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Magnoliavej 28, 2600 Glostrup, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 0,
        date: dateToString(todayPlus2),
        time: "13:00",
        weight: 3000,
        tag: "Morning",
        cargoContent: "One package of wood.",
    },
    {
        from: "Moltkesvej 69, 2000 Frederiksberg, Danmark",
        to: "Høyrups Alle 19, 2900 Hellerup, Denmark",
        orderNumber: generateRandomOrderNumber(),
        customerAtDropoff: 1,
        date: dateToString(todayPlus2),
        time: "15:00",
        weight: 400,
        tag: "During the day",
        cargoContent: "Two packages of glue joint.",
    },
];
