import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    bulkDeleteStopDrafts,
    createStopDrafts,
    getStopDrafts,
} from "../../api/stop-draft";
import { archiveStopDraftTour, getActiveTours } from "../../api/tours";
import { ReduxState } from "../../shared/types/redux";
import { generateDemoStopDrafts } from "../../shared/utility/stop-draft";
import { fakeFromAddresses, fakeToAddresses } from "../../shared/values/lists";
import useContacts from "../data/useContacts";

function useDemoMutations({ onSuccess }: { onSuccess?: () => void }) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);

    const { contacts } = useContacts();

    const {
        mutate: createDemoStopDrafts,
        isPending: isCreatingDemoStopDrafts,
    } = useMutation({
        mutationFn: async () => {
            const stopDrafts = generateDemoStopDrafts({
                region: user?.location_entity?.region?.region_code,
                customersIds: contacts?.map((contact) => contact.id),
            });

            const promises = [];

            for (let i = 0; i < stopDrafts.length; i++) {
                const stops = stopDrafts[i];
                promises.push(createStopDrafts([stops.pickup, stops.dropoff]));
            }

            await Promise.all(promises);
        },
        onSuccess: () => {
            toast.success(t("successMessage.demoStopDraftsCreated"));
            onSuccess?.();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const {
        mutate: createDemoBigVolumeStopDrafts,
        isPending: isCreatingDemoBigVolumeStopDrafts,
    } = useMutation({
        mutationFn: async () => {
            const stopDrafts = generateDemoStopDrafts({
                region: user?.location_entity?.region?.region_code,
                bigVolume: true,
                noDate: true,
                customersIds: contacts?.map((contact) => contact.id),
            });

            const promises = [];

            for (let i = 0; i < stopDrafts.length; i++) {
                const stops = stopDrafts[i];
                promises.push(createStopDrafts([stops.pickup, stops.dropoff]));
            }

            await Promise.all(promises);
        },
        onSuccess: () => {
            toast.success(t("successMessage.demoStopDraftsCreated"));
            onSuccess?.();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const {
        mutate: createTestStopDrafts,
        isPending: isCreatingTestStopDrafts,
    } = useMutation({
        mutationFn: async () => {
            const stopDrafts = generateDemoStopDrafts({
                region: user?.location_entity?.region?.region_code,
                from: fakeFromAddresses,
                to: fakeToAddresses,
                noDate: true,
            });

            const promises = [];

            for (let i = 0; i < stopDrafts.length; i++) {
                const stops = stopDrafts[i];
                promises.push(createStopDrafts([stops.pickup, stops.dropoff]));
            }

            await Promise.all(promises);
        },
        onSuccess: () => {
            toast.success(t("successMessage.testStopDraftsCreated"));
            onSuccess?.();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const { mutate: deleteAllStopDrafts, isPending: isDeletingAllStopDrafts } =
        useMutation({
            mutationFn: async () => {
                const {
                    data: { stop_drafts: stopDrafts },
                } = await getStopDrafts();

                await bulkDeleteStopDrafts(stopDrafts.map((sd) => sd.id));
            },

            onSuccess: () => {
                toast.success(t("successMessage.allStopDraftsDeleted"));
                onSuccess?.();
            },

            onError: () => {
                toast.error(t("errorMessage.unknown"));
            },
        });

    const {
        mutate: archiveAllActiveTours,
        isPending: isArchivingAllActiveTours,
    } = useMutation({
        mutationFn: async () => {
            const { data: tours } = await getActiveTours();
            for (let i = 0; i < tours.length; i++) {
                const tour = tours[i];
                try {
                    await archiveStopDraftTour(tour.tour_id);
                } catch (error) {
                    console.error(error);
                }
            }
        },

        onSuccess: () => {
            toast.success(t("successMessage.allActiveToursArchived"));
            onSuccess?.();
        },

        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    return {
        createDemoStopDrafts,
        isCreatingDemoStopDrafts,
        deleteAllStopDrafts,
        createTestStopDrafts,
        isCreatingTestStopDrafts,
        isDeletingAllStopDrafts,
        archiveAllActiveTours,
        isArchivingAllActiveTours,
        createDemoBigVolumeStopDrafts,
        isCreatingDemoBigVolumeStopDrafts,
    };
}

export default useDemoMutations;
