import Spinner from "../../UI/Spinner";
import "./style.scss";

type Props = {
    isActive: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    label?: string;
    style?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
    isLoading?: boolean;
};

function Switch(props: Props) {
    const switchClasses = ["switch"];
    if (props.disabled) switchClasses.push("disabled");
    if (props.isLoading) switchClasses.push("loading");

    return (
        <label className="switch-wrapper" style={props.style}>
            {props.label && (
                <span className="switch-label text-xs" style={props.labelStyle}>
                    {props.label}
                </span>
            )}
            <div className={switchClasses.join(" ")}>
                <input
                    type="checkbox"
                    checked={props.isActive}
                    onChange={(e) => {
                        if (props.disabled) return;
                        props.onChange(e.target.checked);
                    }}
                />
                <span className="slider">{props.isLoading && <Spinner />}</span>
            </div>
        </label>
    );
}

export default Switch;
