import { useId } from "react";
import { TabOption } from "../../../shared/types/internal";
import TabButton from "./TabButton";
import "./style.scss";

type Props = {
    tabs: TabOption[];
    onTabChange: (tab: TabOption) => void;
    activeTabValue: string;
    style?: React.CSSProperties;
    buttonStyle?: React.CSSProperties;
};

function Tabs(props: Props) {
    const tabsInstanceId = useId();
    return (
        <div className="tabs" style={props.style}>
            {props.tabs.map((tab) => (
                <TabButton
                    key={tab.value}
                    tab={tab}
                    layoutId={tabsInstanceId}
                    isActive={tab.value === props.activeTabValue}
                    onClick={() => props.onTabChange(tab)}
                    style={props.buttonStyle}
                />
            ))}
        </div>
    );
}

export default Tabs;
