import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { faExpand, faSquareCheck } from "@fortawesome/pro-solid-svg-icons";
import { useMap } from "@vis.gl/react-google-maps";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../components/buttons/Button";
import { StopDraft, StopDraftsTour } from "../../shared/types/api";
import { KeyString } from "../../shared/types/internal";
import { ReduxState } from "../../shared/types/redux";
import { getBoundForLocations } from "../../shared/utility/misc";
import {
    DEFAULT_CENTER,
    NEW_TOUR_COLUMN_ID,
    UNHANDLED_STOPS_ID,
} from "../../shared/values/enums";
import { GOOGLE_MAP_IDS } from "../../shared/values/google-map-ids";
import Map from "../Map";
import StopMarkers from "../markers/StopMarkers";
import TourMarkers from "../markers/TourMarkers";
import "./style.scss";

type Props = {
    tours: StopDraftsTour[];
    stops?: StopDraft[];
    unhandledStops?: StopDraft[];
    tourColorMap?: KeyString<{
        primary: string;
        accent: string;
    }>;
    onSelectStops?: (stops: { pickup: StopDraft; dropoff: StopDraft }) => void;
    onSelectMultipleStops?: (
        stops: { pickup: StopDraft; dropoff: StopDraft }[]
    ) => void;
};

function ToursMap(props: Props) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const map = useMap();

    const [showUnhandledStops, setShowUnhandledStops] = useState(true);
    const [focusedStop, setFocusedStop] = useState<StopDraft | null>(null);

    useEffect(() => {
        if (!props.unhandledStops) return;
        if (
            !props.unhandledStops.some(
                (s) => s.id === focusedStop?.id || s.id === focusedStop?.id
            )
        ) {
            setFocusedStop(null);
        }
    }, [props.unhandledStops, focusedStop?.id]);

    const focusStopHandler = useCallback(
        (stop: StopDraft) => setFocusedStop(stop),
        []
    );

    const fitBoundsToStops = useCallback(
        (stops: StopDraft[]) => {
            if (!map) return;

            const bounds = getBoundForLocations(
                stops.map((stop) => ({
                    lat: +stop.lat!,
                    lng: +stop.lng!,
                }))
            );

            if (bounds.isEmpty()) return;

            map.fitBounds(bounds, 50);
        },
        [map]
    );

    return (
        <div className="tours-map">
            <div className="controls">
                {props.stops && props.stops.length > 0 && (
                    <Button
                        key={NEW_TOUR_COLUMN_ID}
                        variant={"secondary"}
                        label={t("bigVolume.plannedStops")}
                        leadingIcon={faExpand}
                        onClick={() => fitBoundsToStops(props.stops!)}
                        leadingIconColor={
                            props.tourColorMap?.[NEW_TOUR_COLUMN_ID].primary
                        }
                    />
                )}

                {props.tours.map((tour) => (
                    <Button
                        key={tour.tour_id}
                        variant={"secondary"}
                        label={
                            tour.preferred_driver ||
                            t("bigVolume.unassignedTourLabel")
                        }
                        leadingIcon={faExpand}
                        onClick={() => fitBoundsToStops(tour.stops)}
                        leadingIconColor={
                            props.tourColorMap?.[tour.tour_id].primary
                        }
                    />
                ))}
            </div>
            <Map
                mapId={GOOGLE_MAP_IDS.ToursMap}
                defaultCenter={
                    user?.location_entity?.lat && user?.location_entity?.lng
                        ? {
                              lat: user.location_entity.lat,
                              lng: user.location_entity.lng,
                          }
                        : DEFAULT_CENTER
                }
                defaultZoom={12}
                showTrafficToggle
                controls={
                    <Button
                        onClick={() =>
                            setShowUnhandledStops((state) => {
                                const newState = !state;
                                if (!newState) {
                                    setFocusedStop(null);
                                }
                                return newState;
                            })
                        }
                        variant={"secondary"}
                        label={t("bigVolume.showUnhandledStops")}
                        leadingIcon={
                            showUnhandledStops ? faSquareCheck : faSquare
                        }
                        leadingIconColor={
                            showUnhandledStops
                                ? "var(--color-primary-400)"
                                : undefined
                        }
                    />
                }
                onClick={() => setFocusedStop(null)}
            >
                {props.tours.map((tour, i) => (
                    <TourMarkers
                        key={tour.tour_id}
                        tour={tour}
                        tourColor={props.tourColorMap?.[tour.tour_id]}
                    />
                ))}

                {props.stops && (
                    <TourMarkers
                        key={NEW_TOUR_COLUMN_ID}
                        stops={props.stops}
                        tourColor={props.tourColorMap?.[NEW_TOUR_COLUMN_ID]}
                    />
                )}

                {props.unhandledStops && showUnhandledStops && (
                    <StopMarkers
                        key={UNHANDLED_STOPS_ID}
                        stops={props.unhandledStops.filter(
                            (stop) =>
                                !props.stops?.some((s) => s.id === stop.id)
                        )}
                        onClick={focusStopHandler}
                        focusedStop={focusedStop || undefined}
                        onSelectStops={(stops) => {
                            props.onSelectStops?.(stops);
                            setFocusedStop(null);
                        }}
                        onSelectMultipleStops={(stops) => {
                            props.onSelectMultipleStops?.(stops);
                            setFocusedStop(null);
                        }}
                    />
                )}
            </Map>
        </div>
    );
}

export default ToursMap;
