import {
    faAnglesDown,
    faAnglesUp,
    faAnglesUpDown,
    faEllipsisVertical,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useCallback, useMemo, useState } from "react";
import Modal from "../../hoc/Modal";
import { useTranslation } from "react-i18next";
import "./style.scss";

type Column<T> = {
    key: keyof T | string;
    title: string | ReactElement;
    getValue?: (item: T, column: Column<T>) => string | number;
    render?: (item: T, column: Column<T>) => ReactElement | null;
    hide?: boolean;
    onSort?: (column: Column<T>) => void;
    sortMode?: "asc" | "desc" | null;
    getHoverTitle?: (item: T, column: Column<T>) => string;
    width?: string;
    getTextColor?: (item: T, column: Column<T>) => string | undefined;
};

type Props<T> = {
    data: T[];
    columns: Column<T>[];
    maxHeight?: string;
    renderModalContent?: (item: T, close: () => void) => ReactElement | null;
    onRowClick?: (item: T) => void;
    emptyStateLabel?: string;
};

function Table<T>(props: Props<T>) {
    const { renderModalContent, onRowClick } = props;
    const { t } = useTranslation();

    const [modalOpenIndex, setModalOpenIndex] = useState<number | null>(null);

    const tableClasses = ["table"];
    if (props.maxHeight) {
        tableClasses.push("scrollable");
    }

    const getValueToDisplay = useCallback((item: T, column: Column<T>) => {
        if (column.render) {
            return column.render(item, column);
        }

        if (column.getValue !== undefined) {
            const value = column.getValue(item, column);

            return (
                <span
                    className="value-wrapper"
                    title={column.getHoverTitle?.(item, column) || `${value}`}
                    style={{
                        color: column.getTextColor?.(item, column) || undefined,
                    }}
                >
                    {value}
                </span>
            );
        }

        const value = item[column.key as keyof typeof item] as string;
        return (
            <span
                className="value-wrapper"
                title={column.getHoverTitle?.(item, column) || `${value}`}
                style={{
                    color: column.getTextColor?.(item, column) || undefined,
                }}
            >
                {item[column.key as keyof typeof item] as string}{" "}
            </span>
        );
    }, []);

    const headers = useMemo(
        () =>
            props.columns
                .filter((column) => !column.hide)
                .map((column, index) => (
                    <th key={`head-cell-${index}`}>
                        <div
                            className="table-head-wrapper text-xs"
                            style={{
                                cursor: column.onSort ? "pointer" : undefined,
                            }}
                            onClick={() => column.onSort?.(column)}
                        >
                            {column.title}{" "}
                            {column.onSort && (
                                <FontAwesomeIcon
                                    icon={
                                        column.sortMode === "asc"
                                            ? faAnglesUp
                                            : column.sortMode === "desc"
                                            ? faAnglesDown
                                            : faAnglesUpDown
                                    }
                                    size="sm"
                                />
                            )}
                        </div>
                    </th>
                )),
        [props.columns]
    );

    const rows = useMemo(
        () =>
            props.data.map((item, i) => {
                const modalContent = renderModalContent?.(item, () =>
                    setModalOpenIndex(null)
                );

                return (
                    <tr
                        key={`row-${i}`}
                        onClick={() => onRowClick?.(item)}
                        style={{ cursor: onRowClick ? "pointer" : undefined }}
                    >
                        {props.columns
                            .filter((column) => !column.hide)
                            .map((column, j) => {
                                const value = getValueToDisplay(item, column);
                                return (
                                    <td
                                        key={`cell-${j}`}
                                        style={{
                                            width: column.width,
                                            minWidth: column.width,
                                            maxWidth: column.width,
                                        }}
                                    >
                                        {value}
                                    </td>
                                );
                            })}
                        {renderModalContent && (
                            <td>
                                {modalContent && (
                                    <Modal
                                        buttonElement={(ref) => (
                                            <button
                                                type="button"
                                                ref={ref}
                                                style={{
                                                    backgroundColor:
                                                        "transparent",
                                                    border: "none",
                                                    padding: "6px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setModalOpenIndex((state) =>
                                                        state === i ? null : i
                                                    );
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEllipsisVertical}
                                                    color={
                                                        "var(--color-neutral-500)"
                                                    }
                                                    size="lg"
                                                    fixedWidth
                                                />
                                            </button>
                                        )}
                                        isOpen={modalOpenIndex === i}
                                        onClose={() => setModalOpenIndex(null)}
                                        align={"right"}
                                        topAlign={i > props.data.length / 2}
                                    >
                                        {modalContent}
                                    </Modal>
                                )}
                            </td>
                        )}
                    </tr>
                );
            }),
        [
            getValueToDisplay,
            modalOpenIndex,
            onRowClick,
            props.columns,
            props.data,
            renderModalContent,
        ]
    );

    return (
        <div
            className={tableClasses.join(" ")}
            style={{
                maxHeight: props.maxHeight,
                height: props.maxHeight,
            }}
        >
            <table>
                <thead>
                    <tr>
                        {headers}
                        {renderModalContent && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {rows.length === 0 ? (
                        <tr>
                            <td
                                colSpan={headers.length + 1}
                                align="center"
                                height={380}
                                style={{
                                    borderBottom: "none",
                                }}
                            >
                                <p
                                    style={{
                                        color: "var(--color-neutral-400)",
                                    }}
                                >
                                    {props.emptyStateLabel ||
                                        t("general.emptyState")}
                                </p>
                            </td>
                        </tr>
                    ) : (
                        rows
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default Table;
