import {
    faCog,
    faColumns,
    faFileCirclePlus,
    faGrid2,
    faRefresh,
    faSearch,
    faSquare,
    faVideoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { DropResult, OnDragEndResponder } from "@hello-pangea/dnd";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    acceptStopDraftLocationChange,
    fulfillStopDraft,
    undoStopDraftLocationChange,
    updateOrderDraft,
} from "../../api/stop-draft";
import {
    removeStopFromTour,
    removeStopFromTourDegrouping,
} from "../../api/tours";
import StopTable from "../../big-volume/tables/StopTable";
import TourTable from "../../big-volume/tables/TourTable";
import Button from "../../components/buttons/Button";
import Input from "../../components/inputs/Input";
import Switch from "../../components/inputs/Switch";
import WeekDaySelector from "../../components/inputs/WeekDaySelector";
import Badge from "../../components/UI/Badge";
import alertPrompt from "../../components/widgets/alertPrompt";
import StopFilterModal from "../../containers/StopFilterModal";
import ToursDragDrop from "../../fleet-planner/ToursDragDrop";
import Modal from "../../hoc/Modal";
import useFleetPlannerData from "../../hooks/data/useFleetPlannerData";
import useBulkDeletion from "../../hooks/functionality/useBulkDeletion";
import useFleetPlannerDrag from "../../hooks/functionality/useFleetPlannerDrag";
import useStopTableAndTourTableFilterAndSort from "../../hooks/functionality/useStopAndTourFilterAndSort";
import { useFleetRoute } from "../../hooks/route/useFleetRoute";
import ToursMap from "../../maps/ToursMap";
import AddDriverPopup from "../../popups/AddDriverPopup";
import ExternalStopsPopup from "../../popups/ExternalStopsPopup";
import GroupTourStopsPopup from "../../popups/GroupTourStopsPopup";
import StopChangeLocationPopup from "../../popups/StopChangeLocationPopup";
import StopFormPopup from "../../popups/StopFormPopup";
import StopsInfoPopup from "../../popups/StopsInfoPopup";
import { OrderDraft, StopDraft, StopDraftsTour } from "../../shared/types/api";
import { KeyString, TabOption } from "../../shared/types/internal";
import { ReduxState } from "../../shared/types/redux";
import { dateToString } from "../../shared/utility/date";
import { getDriverDisplayName } from "../../shared/utility/misc";
import { getCombinedStops } from "../../shared/utility/stop-draft";
import {
    NEW_TOUR_COLUMN_ID,
    STOP_DRAFT_TYPE,
    UNHANDLED_STOPS_ID,
} from "../../shared/values/enums";
import { tourColors } from "../../shared/values/lists";
import { setFleetPlannerSettings } from "../../store/slices/fleetPlanner";
import Tabs from "../../components/buttons/Tabs";
import "./style.scss";

export type StopTableData = {
    stops?: { pickup: StopDraft; dropoff: StopDraft };
    orderDraft?: OrderDraft;
};

export type TourTableData = {
    tour?: StopDraftsTour;
    stops?: {
        pickup: StopDraft;
        dropoff: StopDraft;
    };
};

const MAX_MARK_ALL_STOPS = 100;

type Props = {};

function BigVolume(props: Props) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { filterDate, setFilterDate } = useFleetRoute();

    const { settings } = useSelector((state: ReduxState) => state.fleetPlanner);
    const { user } = useSelector((state: ReduxState) => state.auth);
    const dispatch = useDispatch();

    const {
        // Data
        tours,
        stopDrafts,
        orderDrafts,
        drivers,
        movedStops,

        // Statuses
        isFetchingStopDrafts,
        isFetchingTours,

        reloadData,
    } = useFleetPlannerData({
        filterDate: filterDate ? dateToString(filterDate) : undefined,
    });

    const {
        onDragEndStopDraftInTour,
        columnIdsLoading,
        unhandledIdsLoading,
        sortTourPickupsFirst,
    } = useFleetPlannerDrag({
        filterDate: dateToString(filterDate),
        tours,
        stopDrafts,
        reloadData,
    });

    const {
        sortStopTable,
        sortTourTable,

        filterStopTable,
        filterTourTable,

        activeStopFilter,
        setActiveStopFilter,
        activeStopSort,
        setActiveStopSort,
        stopSearch,
        setStopSearch,

        tourSearch,
        setTourSearch,
        activeTourSort,
        setActiveTourSort,
    } = useStopTableAndTourTableFilterAndSort();

    const {
        bulkDeleteOrderDrafts,
        bulkDeleteStops,
        isBulkDeletingStops,
        isBulkDeletingOrderDrafts,
    } = useBulkDeletion({
        onSuccess: async () => reloadData("stop-drafts", "order-drafts"),
    });

    const [selectedStops, setSelectedStops] = useState<StopDraft[]>([]);
    const [selectedTourIds, setSelectedTourIds] = useState<number[]>([]);
    const [isMarkingAll, setIsMarkingAll] = useState(false);

    const [onlyShowOrderDrafts, setOnlyShowOrderDrafts] = useState(false);

    const [sectionsSize, setSectionsSize] = useState<
        "quarter" | "half" | "full"
    >("quarter");

    const sectionSizes: TabOption[] = useMemo(() => {
        return [
            {
                value: "quarter",
                label: "",
                icon: faGrid2,
            },
            {
                value: "half",
                label: "",
                icon: faColumns,
            },
            {
                value: "full",
                label: "",
                icon: faSquare,
            },
        ];
    }, []);

    //popups
    const [isStopPopupOpen, setIsStopPopupOpen] = useState(false);
    const [isDriverPopupOpen, setIsDriverPopupOpen] = useState(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

    const [editStops, setEditStops] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);
    const [stopPopupData, setStopPopupData] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
        groupedStops?: {
            pickup: StopDraft;
            dropoff: StopDraft;
        }[];
    } | null>(null);
    const [orderDraftForCreate, setOrderDraftForCreate] =
        useState<OrderDraft | null>(null);
    const [changeLocationStops, setChangeLocationStops] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);
    const [stopsForExternalMarking, setStopsForExternalMarking] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);
    const [groupTourId, setGroupTourId] = useState<number | null>(null);

    //loading
    const [detachingIds, setDetachingIds] = useState<number[]>([]);

    useEffect(() => {
        if (!filterDate) return;
        //If a user changes location, we need to reset the selected stops and stuff
        if (!user?.location_id) return;

        setSelectedStops([]);
        setSelectedTourIds([]);
        setIsMarkingAll(false);
    }, [filterDate, user?.location_id]);

    // START FILTER AND SORT
    const combinedStops = useMemo(() => {
        if (!stopDrafts) return [];

        return getCombinedStops(stopDrafts, {
            filterDate: settings.filterUnhandledByDate
                ? filterDate || undefined
                : undefined,
            noExternalStops: true,
            noRequestedStops: true,
        });
    }, [filterDate, settings.filterUnhandledByDate, stopDrafts]);

    const requestedStopsFromOtherLocations = useMemo(() => {
        if (!stopDrafts) return [];

        return getCombinedStops(stopDrafts, {
            filterDate: settings.filterUnhandledByDate
                ? filterDate || undefined
                : undefined,
            noRequestedStops: false,
            noExternalStops: true,
            noUnhandledStops: true,
        });
    }, [filterDate, settings.filterUnhandledByDate, stopDrafts]);

    const stopTableData = useMemo<StopTableData[]>(() => {
        const data: StopTableData[] = [];

        if (!onlyShowOrderDrafts) {
            for (let i = 0; i < requestedStopsFromOtherLocations.length; i++) {
                const stops = requestedStopsFromOtherLocations[i];

                data.push({
                    stops,
                });
            }
        }

        if (!onlyShowOrderDrafts) {
            for (let i = 0; i < combinedStops.length; i++) {
                const stops = combinedStops[i];

                data.push({
                    stops,
                });
            }
        }

        if (orderDrafts) {
            for (let i = 0; i < orderDrafts.length; i++) {
                const orderDraft = orderDrafts[i];

                data.push({
                    orderDraft,
                });
            }
        }

        return data;
    }, [
        onlyShowOrderDrafts,
        orderDrafts,
        combinedStops,
        requestedStopsFromOtherLocations,
    ]);

    const filteredAndStortedStopTableData = useMemo(() => {
        let result = stopTableData.filter(filterStopTable).sort(sortStopTable);

        if (onlyShowOrderDrafts) {
            result = result.filter(({ orderDraft }) => !!orderDraft);
        }

        return result;
    }, [filterStopTable, onlyShowOrderDrafts, sortStopTable, stopTableData]);

    const stopsSentToOtherLocations = useMemo(() => {
        if (!movedStops) return [];

        return getCombinedStops(movedStops, {
            filterDate: settings.filterUnhandledByDate
                ? filterDate || undefined
                : undefined,
        });
    }, [filterDate, movedStops, settings.filterUnhandledByDate]);

    const externalStops = useMemo(() => {
        if (!stopDrafts) return [];

        return getCombinedStops(stopDrafts, {
            filterDate: settings.filterUnhandledByDate
                ? filterDate || undefined
                : undefined,
            noUnhandledStops: true,
            noRequestedStops: true,
        });
    }, [filterDate, settings.filterUnhandledByDate, stopDrafts]);

    const tourTableData = useMemo<TourTableData[]>(() => {
        if (!tours) return [];

        const data: TourTableData[] = [];

        for (let i = 0; i < tours.length; i++) {
            const tour = tours[i];

            data.push({
                tour,
            });
        }

        for (let i = 0; i < stopsSentToOtherLocations.length; i++) {
            const stops = stopsSentToOtherLocations[i];
            data.push({
                stops,
            });
        }

        for (let i = 0; i < externalStops.length; i++) {
            const stops = externalStops[i];
            data.push({
                stops,
            });
        }

        return data;
    }, [externalStops, stopsSentToOtherLocations, tours]);

    const sortedTourTableData = useMemo(() => {
        if (!tourTableData) return [];

        return tourTableData.filter(filterTourTable).sort(sortTourTable);
    }, [filterTourTable, sortTourTable, tourTableData]);

    const selectedTours = useMemo(
        () => tours?.filter((t) => selectedTourIds.includes(t.tour_id)) || [],
        [selectedTourIds, tours]
    );
    // END FILTER AND SORT

    const groupTour = useMemo(() => {
        if (!tours) return null;

        return tours.find((tour) => tour.tour_id === groupTourId) || null;
    }, [groupTourId, tours]);

    const onReorderNewTour = useCallback(
        (result: DropResult) => {
            if (!result.destination) return;
            const { source, destination } = result;

            setSelectedStops((state) => {
                const newSelectedStops = [...state];

                const [removed] = newSelectedStops.splice(source.index, 1);
                newSelectedStops.splice(destination.index, 0, removed);

                const groupedStopIndex = newSelectedStops.findIndex(
                    (sd) =>
                        sd.group_id === removed?.group_id &&
                        sd.id !== removed.id
                );

                if (
                    removed.stop_type_id === STOP_DRAFT_TYPE.Pickup &&
                    destination.index > groupedStopIndex
                ) {
                    toast.error(t("errorMessage.invalidMovePickup"));
                    return state;
                } else if (
                    removed.stop_type_id === STOP_DRAFT_TYPE.Dropoff &&
                    destination.index < groupedStopIndex
                ) {
                    toast.error(t("errorMessage.invalidMoveDropoff"));
                    return state;
                }

                return newSelectedStops;
            });
        },
        [t]
    );

    const onDragEnd = useCallback<OnDragEndResponder>(
        async (result, provided) => {
            if (!result.destination) return;
            const { source, destination, draggableId } = result;

            //return if the card is dropped in the same place it was picked up
            if (
                destination.droppableId === source.droppableId &&
                destination.index === source.index
            )
                return;

            if (
                source.droppableId === NEW_TOUR_COLUMN_ID &&
                destination.droppableId === NEW_TOUR_COLUMN_ID
            ) {
                onReorderNewTour(result);
                return;
            }

            if (
                destination.droppableId === NEW_TOUR_COLUMN_ID &&
                source.droppableId !== NEW_TOUR_COLUMN_ID
            ) {
                toast.error(
                    t("errorMessage.cantMoveStopsToNewTourNeedsDetachment")
                );
                return;
            }

            await onDragEndStopDraftInTour({
                draggableId,
                source,
                destination,
                isFromUnhandledColumn:
                    source.droppableId === NEW_TOUR_COLUMN_ID,
                isDroppedInEmptyColumn: false,
                createdFrom: "big-volume",
                allowMoveGroupedStops: true,
                dontAllowGroupedStopsChangeColumn: true,
            });
        },
        [onDragEndStopDraftInTour, onReorderNewTour, t]
    );

    const detachStopHandler = useCallback(
        async (stop: StopDraft) => {
            const tour = tours?.find((t) => t.tour_id === stop.tour_id);

            if (!tour) return;

            setDetachingIds((state) => [...state, stop.id]);

            try {
                await removeStopFromTourDegrouping(stop, tour);

                toast.success(t("activeFleet.stopDetached"));

                await reloadData("tours", "stop-drafts");
            } catch (error) {
                toast.error(t("errorMessage.stopDetachedError"));
            } finally {
                setDetachingIds((state) =>
                    state.filter((id) => id !== stop.id)
                );
            }
        },
        [reloadData, t, tours]
    );

    const detachExternalStopHandler = useCallback(
        async (stop: StopDraft) => {
            try {
                await removeStopFromTour(stop.id);

                toast.success(t("activeFleet.stopDetached"));

                await reloadData("stop-drafts");
            } catch (error) {
                toast.error(t("errorMessage.stopDetachedError"));
            }
        },
        [reloadData, t]
    );

    const fulfillExternalStopHandler = useCallback(
        async (stopDraft: StopDraft) => {
            try {
                await fulfillStopDraft(stopDraft.id);
                queryClient.removeQueries({
                    queryKey: ["fulfilled-fleet"],
                    type: "all",
                });
                await reloadData("stop-drafts");
                toast.success(t("activeFleet.stopFulfilled"));
            } catch (error) {
                toast.error(t("errorMessage.stopFulfilledError"));
            }
        },
        [queryClient, reloadData, t]
    );

    const respondToStopRequestHandler = useCallback(
        async ({
            pickup,
            dropoff,
            response,
        }: {
            pickup: StopDraft;
            dropoff: StopDraft;
            response: "accept" | "reject";
        }) => {
            if (response === "accept") {
                try {
                    await acceptStopDraftLocationChange([
                        pickup.id,
                        dropoff.id,
                    ]);
                    toast.success(t("activeFleet.stopAccepted"));
                    await reloadData("stop-drafts", "moved-stops");
                } catch (error) {
                    toast.error(t("errorMessage.unknown"));
                }
                return;
            }

            if (response === "reject") {
                try {
                    await undoStopDraftLocationChange([pickup.id, dropoff.id]);

                    toast.success(t("activeFleet.stopSentBack"));
                    await reloadData("stop-drafts", "moved-stops");
                } catch (error) {
                    toast.error(t("errorMessage.unknown"));
                }
            }
        },
        [reloadData, t]
    );

    const onStopsCreated = useCallback(
        async (createdIds: number[]) => {
            setIsStopPopupOpen(false);

            if (orderDraftForCreate) {
                try {
                    await updateOrderDraft({
                        id: orderDraftForCreate.id,
                        stopDraftIds: createdIds,
                    });
                } catch (error) {
                    toast.error(
                        t(
                            "errorMessage.orderDraftUpdatedStopDraftsCreatedError"
                        )
                    );
                } finally {
                    setOrderDraftForCreate(null);
                }
            }

            reloadData("stop-drafts", "tours", "order-drafts");
        },
        [orderDraftForCreate, reloadData, t]
    );

    const bulkDeleteHandler = useCallback(
        async (type: "stop" | "order-draft") => {
            const accepted = await alertPrompt({
                title:
                    type === "stop"
                        ? t("activeFleet.deleteAllStopDraftsTitle")
                        : t("activeFleet.deleteAllOrderDraftsTitle"),
                message:
                    type === "stop"
                        ? t("activeFleet.deleteAllStopDraftsMessage")
                        : t("activeFleet.deleteAllOrderDraftsMessage"),
                confirmText: "OK",
            });

            if (!accepted) return;

            if (type === "stop") {
                bulkDeleteStops();
            } else if (type === "order-draft") {
                bulkDeleteOrderDrafts();
            }
        },
        [bulkDeleteOrderDrafts, bulkDeleteStops, t]
    );

    const tourColorMap = useMemo(() => {
        const newMap: KeyString<{
            primary: string;
            accent: string;
        }> = {};
        newMap[NEW_TOUR_COLUMN_ID] = tourColors[tourColors.length - 1];
        newMap[UNHANDLED_STOPS_ID] = tourColors[tourColors.length - 1];

        if (!tours) return newMap;

        for (let i = 0; i < tours.length; i++) {
            const tour = tours[i];

            newMap[tour.tour_id] = tourColors[i % tourColors.length];
        }

        return newMap;
    }, [tours]);

    useEffect(() => {
        if (!tours) return;

        setSelectedTourIds((state) =>
            state.filter((id) => tours.some((t) => t.tour_id === id))
        );
    }, [tours]);

    useEffect(() => {
        if (!stopDrafts) return;

        setSelectedStops((state) =>
            state.filter(({ id }) => stopDrafts.some((s) => s.id === id))
        );
    }, [stopDrafts]);

    useEffect(() => {
        if (!isMarkingAll) return;

        if (filteredAndStortedStopTableData.length > MAX_MARK_ALL_STOPS) {
            setIsMarkingAll(false);
            return;
        }

        const stopsInTable = filteredAndStortedStopTableData
            .filter(
                ({ stops }) =>
                    !!stops &&
                    (stops.pickup.origin_location
                        ? !!stops.pickup.move_accepted_at
                        : true)
            )
            .flatMap(({ stops }) =>
                stops ? [stops.pickup, stops.dropoff] : []
            );

        setSelectedStops(stopsInTable);
    }, [filteredAndStortedStopTableData, isMarkingAll]);

    return (
        <div className="big-volume">
            <div className="controls">
                <WeekDaySelector value={filterDate} onChange={setFilterDate} />

                <div className="right-controls">
                    <Tabs
                        tabs={sectionSizes}
                        activeTabValue={sectionsSize}
                        onTabChange={({ value }) =>
                            setSectionsSize(
                                value as "quarter" | "half" | "full"
                            )
                        }
                    />

                    <div className="buttons">
                        <a
                            href="https://drive.google.com/file/d/1Lt-SLriK0liXNEg8C3HiOBPk0MTHg_Dg/view?usp=drive_link"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                label={t("bigVolume.newUpdates")}
                                variant="primary"
                                noSubmit
                                leadingIcon={faVideoCircle}
                            />
                        </a>

                        <Button
                            variant="secondary"
                            label={t("activeFleet.updateAllData")}
                            leadingIcon={faRefresh}
                            onClick={() =>
                                reloadData(
                                    "tours",
                                    "stop-drafts",
                                    "moved-stops",
                                    "order-drafts"
                                )
                            }
                            isLoading={isFetchingStopDrafts || isFetchingTours}
                        />

                        <Modal
                            buttonElement={(ref) => (
                                <Button
                                    ref={ref}
                                    variant={"secondary"}
                                    label={t("bigVolume.settings")}
                                    leadingIcon={faCog}
                                    onClick={() => setIsSettingsModalOpen(true)}
                                />
                            )}
                            isOpen={isSettingsModalOpen}
                            onClose={() => setIsSettingsModalOpen(false)}
                            align={"right"}
                            width="300px"
                        >
                            <div className="settings-modal">
                                <section>
                                    <span className="text-lg">
                                        {t("activeFleet.settingsTitle")}
                                    </span>
                                </section>
                                <section>
                                    <span>
                                        {t(
                                            "fleetPlanner.filterUnhandledByDateSettingsTitle"
                                        )}
                                    </span>
                                    <Switch
                                        isActive={
                                            !!settings.filterUnhandledByDate
                                        }
                                        onChange={(value) =>
                                            dispatch(
                                                setFleetPlannerSettings({
                                                    filterUnhandledByDate: value
                                                        ? 1
                                                        : 0,
                                                })
                                            )
                                        }
                                    />
                                </section>
                                <section>
                                    <span className="text-lg">
                                        {t("activeFleet.bulkDeleteTitle")}
                                    </span>
                                </section>
                                <section>
                                    <Button
                                        variant={"secondary"}
                                        label={t("activeFleet.bulkDeleteStops")}
                                        onClick={() =>
                                            bulkDeleteHandler("stop")
                                        }
                                        isLoading={isBulkDeletingStops}
                                        style={{ width: "100%" }}
                                    />
                                </section>
                                <section>
                                    <Button
                                        variant={"secondary"}
                                        label={t(
                                            "activeFleet.bulkDeleteOrderDrafts"
                                        )}
                                        onClick={() =>
                                            bulkDeleteHandler("order-draft")
                                        }
                                        isLoading={isBulkDeletingOrderDrafts}
                                        style={{ width: "100%" }}
                                    />
                                </section>
                                <section>
                                    <span className="text-lg">
                                        {t("activeFleet.hideDriversTitle")}
                                    </span>
                                </section>
                                {drivers?.map((driver) => (
                                    <section key={driver.id}>
                                        <span>
                                            {getDriverDisplayName(driver)}
                                        </span>
                                        <Switch
                                            isActive={
                                                !settings?.hiddenDriverIds?.[
                                                    driver.mt_driver_id || ""
                                                ]
                                            }
                                            onChange={(value) =>
                                                dispatch(
                                                    setFleetPlannerSettings({
                                                        hiddenDriverIds: {
                                                            ...settings?.hiddenDriverIds,
                                                            [driver.mt_driver_id ||
                                                            ""]: value ? 0 : 1,
                                                        },
                                                    })
                                                )
                                            }
                                        />
                                    </section>
                                ))}
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
            <div className={["content", sectionsSize].join(" ")}>
                <section className="table-section">
                    <div className="controls">
                        <div className="info">
                            <p className="text-base">
                                {t("bigVolume.unhandledTitle")}
                            </p>
                            <Badge
                                variant={
                                    filteredAndStortedStopTableData.length > 100
                                        ? "error"
                                        : filteredAndStortedStopTableData.length >
                                          50
                                        ? "warning"
                                        : "success"
                                }
                                title={filteredAndStortedStopTableData.length.toString()}
                            />
                        </div>
                        <div className="button-group">
                            <Input
                                placeholder={t(
                                    "bigVolume.searchStopPlaceholder"
                                )}
                                leadingIcon={faSearch}
                                type="text"
                                value={stopSearch}
                                onChange={setStopSearch}
                                style={{ width: "125px" }}
                            />
                            <Button
                                leadingIcon={faFileCirclePlus}
                                variant={"secondary"}
                                label={t("bigVolume.addStop")}
                                onClick={() => setIsStopPopupOpen(true)}
                            />
                            <StopFilterModal
                                stops={combinedStops.flatMap(
                                    ({ pickup, dropoff }) => [pickup, dropoff]
                                )}
                                stopTableData={stopTableData}
                                activeFilter={activeStopFilter}
                                showOnlyOrderDrafts={onlyShowOrderDrafts}
                                onShowOnlyOrderDraftsChange={
                                    setOnlyShowOrderDrafts
                                }
                                onFilterChange={(key, value) =>
                                    setActiveStopFilter((state) => ({
                                        ...state,
                                        [key]: value,
                                    }))
                                }
                                onClearFilter={() =>
                                    setActiveStopFilter(() => ({
                                        tags: [],
                                        pickupCities: [],
                                        dropoffCities: [],
                                    }))
                                }
                            />
                        </div>
                    </div>
                    <StopTable
                        data={filteredAndStortedStopTableData}
                        onRowClick={({ stops, orderDraft }) => {
                            if (stops) {
                                setStopPopupData({
                                    pickup: stops.pickup,
                                    dropoff: stops.dropoff,
                                });
                            }
                            if (orderDraft) {
                                setOrderDraftForCreate(orderDraft);
                                setIsStopPopupOpen(true);
                            }
                        }}
                        sectionsSize={sectionsSize}
                        //Mark all
                        isMarkingAll={isMarkingAll}
                        maxMarkAllStops={MAX_MARK_ALL_STOPS}
                        onMarkingAllChange={(newCheck) => {
                            setIsMarkingAll(newCheck);
                            if (!newCheck) {
                                setSelectedStops([]);
                            }
                        }}
                        //Selection
                        selectedStops={selectedStops}
                        onSelectedStopsChange={(newChecked, stops) =>
                            setSelectedStops((state) => {
                                if (newChecked) {
                                    return [
                                        ...new Set([
                                            ...state,
                                            stops.pickup,
                                            stops.dropoff,
                                        ]),
                                    ];
                                }

                                return state.filter(
                                    (stop) =>
                                        stop !== stops.pickup &&
                                        stop !== stops.dropoff
                                );
                            })
                        }
                        //Sorting
                        activeSort={activeStopSort}
                        onSortChange={setActiveStopSort}
                    />
                </section>

                <section>
                    <ToursMap
                        tours={selectedTours}
                        stops={selectedStops}
                        unhandledStops={filteredAndStortedStopTableData.flatMap(
                            ({ stops }) =>
                                stops ? [stops.pickup, stops.dropoff] : []
                        )}
                        tourColorMap={tourColorMap}
                        onSelectStops={({ pickup, dropoff }) =>
                            setSelectedStops((state) => [
                                ...new Set([...state, pickup, dropoff]),
                            ])
                        }
                        onSelectMultipleStops={(stops) =>
                            setSelectedStops((state) => [
                                ...new Set([
                                    ...state,
                                    ...stops.flatMap((s) => [
                                        s.pickup,
                                        s.dropoff,
                                    ]),
                                ]),
                            ])
                        }
                    />
                </section>

                <section className="table-section">
                    <div className="controls">
                        <div className="info">
                            <p className="text-base">
                                {t("bigVolume.toursTitle")}
                            </p>
                            <Badge
                                variant={"neutral"}
                                title={tours ? tours.length.toString() : "0"}
                            />
                        </div>
                        <div className="button-group">
                            <Input
                                placeholder={t(
                                    "bigVolume.searchTourPlaceholder"
                                )}
                                leadingIcon={faSearch}
                                type="text"
                                value={tourSearch}
                                onChange={setTourSearch}
                            />
                        </div>
                    </div>
                    <TourTable
                        data={sortedTourTableData}
                        sectionsSize={sectionsSize}
                        //Selection
                        selectedTourIds={selectedTourIds}
                        onSelectedTourIdsChange={(newChecked, tourId) =>
                            setSelectedTourIds((state) =>
                                newChecked
                                    ? [...state, tourId]
                                    : state.filter((id) => id !== tourId)
                            )
                        }
                        onRowClickStops={(stops) =>
                            setStopPopupData({
                                pickup: stops.pickup,
                                dropoff: stops.dropoff,
                            })
                        }
                        //Sort
                        activeSort={activeTourSort}
                        onSortChange={setActiveTourSort}
                        //Actions
                        onGroupTourClick={setGroupTourId}
                    />
                </section>

                <section className="drag-drop-section">
                    <ToursDragDrop
                        tours={selectedTours}
                        stops={selectedStops}
                        filterDate={filterDate}
                        onDragEnd={onDragEnd}
                        columnIdsLoading={columnIdsLoading}
                        stopIdsLoading={[
                            ...unhandledIdsLoading,
                            ...detachingIds,
                        ]}
                        tourColorMap={tourColorMap}
                        onStopCardClick={setStopPopupData}
                        onClearStops={() => setSelectedStops([])}
                        onSelectTourId={(tourId) =>
                            setSelectedTourIds((state) => [
                                ...new Set([...state, tourId]),
                            ])
                        }
                    />
                </section>
            </div>

            <AddDriverPopup
                showPopup={isDriverPopupOpen}
                onClose={() => setIsDriverPopupOpen(false)}
            />

            <StopFormPopup
                showPopup={isStopPopupOpen}
                onClose={() => {
                    setIsStopPopupOpen(false);
                    setEditStops(null);
                    setOrderDraftForCreate(null);
                }}
                onCreated={onStopsCreated}
                onEdited={() => {
                    reloadData("stop-drafts", "tours");
                    setIsStopPopupOpen(false);
                    setEditStops(null);
                    setOrderDraftForCreate(null);
                }}
                onDeleted={() => {
                    reloadData("stop-drafts", "tours");
                    setIsStopPopupOpen(false);
                    setEditStops(null);
                    setOrderDraftForCreate(null);
                }}
                editStops={editStops}
                orderDraft={orderDraftForCreate}
            />

            {stopPopupData && (
                <StopsInfoPopup
                    showPopup={!!stopPopupData}
                    onClose={() => setStopPopupData(null)}
                    stops={stopPopupData}
                    groupedStops={stopPopupData.groupedStops}
                    onEditStops={(stops) => {
                        setEditStops(stops);
                        setStopPopupData(null);
                        setIsStopPopupOpen(true);
                    }}
                    onDetachStops={(stops) => {
                        detachStopHandler(stops.pickup);
                        setStopPopupData(null);
                    }}
                    onAssignOutsideFleet={(stops) => {
                        setStopsForExternalMarking(stops);
                        setStopPopupData(null);
                    }}
                    onChangedLocation={(stops) => {
                        setChangeLocationStops(stops);
                        setStopPopupData(null);
                    }}
                    onDeselectStops={
                        selectedStops.some(
                            (stop) => stop.id === stopPopupData.pickup.id
                        )
                            ? (stops) => {
                                  setSelectedStops((state) =>
                                      state.filter(
                                          (stop) =>
                                              stop.id !== stops.pickup.id &&
                                              stop.id !== stops.dropoff.id
                                      )
                                  );
                                  setStopPopupData(null);
                              }
                            : undefined
                    }
                    onRespondToStopRequest={(data) => {
                        respondToStopRequestHandler(data);
                        setStopPopupData(null);
                    }}
                    onSendBackToOrginLocation={(stops) => {
                        respondToStopRequestHandler({
                            ...stops,
                            response: "reject",
                        });
                        setStopPopupData(null);
                    }}
                    onFulfillExternalStop={({ pickup }) => {
                        fulfillExternalStopHandler(pickup);
                        setStopPopupData(null);
                    }}
                    onDetachExternalStop={({ pickup }) => {
                        detachExternalStopHandler(pickup);
                        setStopPopupData(null);
                    }}
                />
            )}

            {changeLocationStops && (
                <StopChangeLocationPopup
                    showPopup={!!changeLocationStops}
                    onClose={() => setChangeLocationStops(null)}
                    stopIds={[
                        changeLocationStops.pickup.id,
                        changeLocationStops.dropoff.id,
                    ]}
                    onLocationChange={() =>
                        reloadData("stop-drafts", "moved-stops")
                    }
                />
            )}

            {stopsForExternalMarking && (
                <ExternalStopsPopup
                    showPopup={!!stopsForExternalMarking}
                    onClose={() => setStopsForExternalMarking(null)}
                    onSubmit={() => reloadData("stop-drafts")}
                    {...stopsForExternalMarking}
                    preselectedDate={dateToString(filterDate)}
                />
            )}

            {!!groupTour && (
                <GroupTourStopsPopup
                    showPopup={!!groupTour}
                    onClose={() => setGroupTourId(null)}
                    droppableId={groupTour.tour_id.toString()}
                    onDragEnd={onDragEnd}
                    isColumnLoading={columnIdsLoading.includes(
                        groupTour.tour_id.toString()
                    )}
                    tour={groupTour}
                    onStopsGrouped={() => reloadData("tours")}
                    onSortClick={() =>
                        sortTourPickupsFirst({
                            tourId: groupTour.tour_id,
                            stops: groupTour.stops,
                        })
                    }
                />
            )}
        </div>
    );
}

export default BigVolume;
