import { faChevronDown, faSparkles } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Modal from "../../../hoc/Modal";
import { NavItem } from "../../../shared/types/internal";
import Badge from "../../UI/Badge";
import "./style.scss";

type Props = {
    to: string;
    label: string;
    subNavItems?: NavItem[];
    onClick?: (e: React.MouseEvent) => void;
};

function NavButton(props: Props) {
    const { pathname } = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(false);
    }, [pathname]);

    return (
        <div className="nav-button-wrapper">
            {props.subNavItems && props.subNavItems.length > 0 ? (
                <Modal
                    buttonElement={(ref) => (
                        <button
                            type="button"
                            className="expandable-nav-button"
                            ref={ref}
                            style={
                                pathname.includes(props.to)
                                    ? {
                                          color: "var(--color-neutral-900",
                                          fontWeight: 600,
                                      }
                                    : {}
                            }
                        >
                            <p className="text-sm">{props.label}</p>
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                size="lg"
                                color="var(--color-neutral-400)"
                            />
                        </button>
                    )}
                    onHoverStart={() => setIsOpen(true)}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    align="right"
                    width="220px"
                >
                    {props.subNavItems.map((subNav) => (
                        <NavLink
                            key={subNav.id}
                            to={subNav.path}
                            onClick={(e) => {
                                setIsOpen(false);
                                props.onClick?.(e);
                            }}
                        >
                            {({ isActive }) => (
                                <button
                                    className="nav-button"
                                    type="button"
                                    style={{
                                        color: isActive
                                            ? "var(--color-neutral-900)"
                                            : "var(--color-neutral-500)",
                                    }}
                                >
                                    <p className="text-sm">{subNav.label}</p>
                                    {subNav.isBeta && (
                                        <Badge
                                            variant="success"
                                            title="Beta"
                                            icon={faSparkles}
                                        />
                                    )}
                                </button>
                            )}
                        </NavLink>
                    ))}
                </Modal>
            ) : (
                <NavLink to={props.to} onClick={props.onClick}>
                    {({ isActive }) => (
                        <button
                            type="button"
                            className="nav-button"
                            style={{
                                color: isActive
                                    ? "var(--color-neutral-900)"
                                    : "var(--color-neutral-500)",
                            }}
                        >
                            <p className="text-sm">{props.label}</p>
                        </button>
                    )}
                </NavLink>
            )}
        </div>
    );
}

export default NavButton;
