import {
    faChevronDown,
    faChevronRight,
    faFilter,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/buttons/Button";
import TextButton from "../../components/buttons/TextButton";
import Checkbox from "../../components/inputs/Checkbox";
import Switch from "../../components/inputs/Switch";
import Modal from "../../hoc/Modal";
import useStopFilter from "../../hooks/functionality/useStopFilter";
import { StopTableData } from "../../pages/BigVolume";
import { StopDraft } from "../../shared/types/api";
import { KeyString, StopFilter } from "../../shared/types/internal";
import { STOP_DRAFT_TYPE } from "../../shared/values/enums";
import "./style.scss";

type FilterSections = keyof StopFilter;

type Props = {
    stops: StopDraft[];
    stopTableData: StopTableData[];
    activeFilter: StopFilter;
    onFilterChange: (key: FilterSections, value: string[]) => void;
    onClearFilter: () => void;
    showOnlyOrderDrafts: boolean;
    onShowOnlyOrderDraftsChange: (value: boolean) => void;
};

const MAX_INITAL_ITEMS = 5;

function StopFilterModal(props: Props) {
    const { t } = useTranslation();
    const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
    const [openSection, setOpenSection] = useState<FilterSections | null>(null);
    const [showFullListSection, setShowFullListSection] =
        useState<FilterSections | null>(null);

    const { filterStops } = useStopFilter({
        activeFilter: props.activeFilter,
        stopSearch: "",
        excludeFilter: openSection || undefined,
    });

    const { tagsCount, pickupCityCount, dropoffCityCount } = useMemo(() => {
        const tagsCount: KeyString<number> = {};
        const pickupCityCount: KeyString<number> = {};
        const dropoffCityCount: KeyString<number> = {};

        for (let i = 0; i < props.stops.length; i++) {
            const stop = props.stops[i];

            if (stop.stop_type_id === STOP_DRAFT_TYPE.Pickup) {
                if (stop.tag) {
                    tagsCount[stop.tag] = 0;
                }

                const city = stop.city || stop.to_location;
                pickupCityCount[city] = 0;
            }

            if (stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff) {
                const city = stop.city || stop.to_location;
                dropoffCityCount[city] = 0;
            }
        }

        const filteredList = props.stopTableData.filter(filterStops);

        for (let i = 0; i < filteredList.length; i++) {
            const { stops } = filteredList[i];

            if (stops) {
                if (stops.pickup.tag) {
                    tagsCount[stops.pickup.tag] =
                        (tagsCount[stops.pickup.tag] || 0) + 1;
                }

                if (stops.pickup.city) {
                    pickupCityCount[stops.pickup.city] =
                        (pickupCityCount[stops.pickup.city] || 0) + 1;
                }

                if (stops.dropoff.city) {
                    dropoffCityCount[stops.dropoff.city] =
                        (dropoffCityCount[stops.dropoff.city] || 0) + 1;
                }
            }
        }

        return {
            tagsCount,
            pickupCityCount,
            dropoffCityCount,
        };
    }, [filterStops, props.stopTableData, props.stops]);

    const isAnyFilterActive = useMemo(() => {
        return (
            Object.values(props.activeFilter).some((v) => v.length > 0) ||
            props.showOnlyOrderDrafts
        );
    }, [props.activeFilter, props.showOnlyOrderDrafts]);

    const filterSections: {
        key: FilterSections;
        title: string;
        count: KeyString<number>;
    }[] = useMemo(() => {
        return [
            {
                key: "tags",
                title: t("bigVolume.tag"),
                count: tagsCount,
            },
            {
                key: "pickupCities",
                title: t("bigVolume.loading"),
                count: pickupCityCount,
            },
            {
                key: "dropoffCities",
                title: t("bigVolume.unloading"),
                count: dropoffCityCount,
            },
        ];
    }, [dropoffCityCount, pickupCityCount, t, tagsCount]);

    const sortSections = useCallback(
        (aKey: string, bKey: string, count: KeyString<number>) => {
            if (count[aKey] === 0 && count[bKey] !== 0) {
                return -1;
            }
            if (count[aKey] !== 0 && count[bKey] === 0) {
                return -1;
            }

            return aKey.localeCompare(bKey);
        },
        []
    );

    return (
        <Modal
            buttonElement={(ref) => (
                <Button
                    ref={ref}
                    leadingIcon={faFilter}
                    label={t("bigVolume.filter")}
                    variant={"secondary"}
                    onClick={() => setIsFilterPopupOpen((state) => !state)}
                />
            )}
            isOpen={isFilterPopupOpen}
            onClose={() => setIsFilterPopupOpen(false)}
            align="right"
            width="300px"
        >
            <div className="stop-filter-modal">
                <div className="top">
                    <div className="header">
                        <FontAwesomeIcon icon={faFilter} size="lg" />
                        <p className="text-sm">{t("bigVolume.filter")}</p>
                        {isAnyFilterActive && (
                            <TextButton
                                variant={"primary"}
                                label={t("bigVolume.clearAllFilter")}
                                onClick={() => {
                                    props.onClearFilter();
                                    props.onShowOnlyOrderDraftsChange(false);
                                }}
                                style={{ marginLeft: "auto" }}
                            />
                        )}
                    </div>
                    <Switch
                        isActive={props.showOnlyOrderDrafts}
                        onChange={(value) => {
                            props.onClearFilter();
                            props.onShowOnlyOrderDraftsChange(value);
                        }}
                        label={t("bigVolume.showOnlyOrderDrafts")}
                        style={{
                            justifyContent: "space-between",
                        }}
                        labelStyle={{
                            color: "var(--color-pure-black)",
                        }}
                    />
                </div>
                {filterSections.map((section) => (
                    <section key={section.key}>
                        <div className="top">
                            <button
                                className="filter-expand-button"
                                type="button"
                                onClick={() =>
                                    setOpenSection((state) =>
                                        state === section.key
                                            ? null
                                            : section.key
                                    )
                                }
                            >
                                <FontAwesomeIcon
                                    icon={
                                        openSection === section.key
                                            ? faChevronDown
                                            : faChevronRight
                                    }
                                    color="var(--color-neutral-600)"
                                    fixedWidth
                                />
                                <p className="text-sm">{section.title}</p>
                            </button>
                            {props.activeFilter[section.key].length > 0 && (
                                <TextButton
                                    variant={"primary"}
                                    label={t("bigVolume.clearFilter")}
                                    onClick={() => {
                                        props.onFilterChange(section.key, []);
                                    }}
                                    style={{ marginLeft: "auto" }}
                                />
                            )}
                        </div>

                        {openSection === section.key && (
                            <div className="option-list">
                                {Object.keys(section.count)
                                    .sort((a, b) =>
                                        sortSections(a, b, section.count)
                                    )
                                    .filter(
                                        (_, i) =>
                                            showFullListSection ===
                                                section.key ||
                                            i < MAX_INITAL_ITEMS
                                    )
                                    .map((value) => (
                                        <Checkbox
                                            key={value}
                                            checked={props.activeFilter[
                                                section.key
                                            ].includes(value)}
                                            onChange={(newChecked) =>
                                                props.onFilterChange(
                                                    section.key,
                                                    newChecked
                                                        ? [
                                                              ...props
                                                                  .activeFilter[
                                                                  section.key
                                                              ],
                                                              value,
                                                          ]
                                                        : props.activeFilter[
                                                              section.key
                                                          ].filter(
                                                              (v) => v !== value
                                                          )
                                                )
                                            }
                                            disabled={
                                                section.count[value] === 0
                                            }
                                            label={value.toString()}
                                            subLabel={section.count[
                                                value
                                            ].toString()}
                                        />
                                    ))}
                                {showFullListSection === section.key ? (
                                    <TextButton
                                        variant={"primary"}
                                        label={t("bigVolume.showLessFilter")}
                                        onClick={() => {
                                            setShowFullListSection(null);
                                        }}
                                        style={{
                                            marginRight: "auto",
                                            marginTop: "12px",
                                        }}
                                    />
                                ) : Object.keys(section.count).length >
                                  MAX_INITAL_ITEMS ? (
                                    <TextButton
                                        variant={"primary"}
                                        label={t("bigVolume.showMoreFilter")}
                                        onClick={() => {
                                            setShowFullListSection(section.key);
                                        }}
                                        style={{
                                            marginRight: "auto",
                                            marginTop: "12px",
                                        }}
                                    />
                                ) : null}
                            </div>
                        )}
                    </section>
                ))}
            </div>
        </Modal>
    );
}

export default StopFilterModal;
