import { differenceInDays, isAfter, isBefore, startOfToday } from "date-fns";
import { z } from "zod";
import { isValidPhoneNumber } from "../shared/utility/phone";
import { DELIVERY_NAME } from "../shared/values/enums";

const consigneeSchema = z.object({
    consigneeName: z.string(),
    consigneePhone: z.string().refine((phone) => {
        if (phone === "") {
            return true;
        }
        return isValidPhoneNumber(phone);
    }, "errorMessage.invalidPhoneNumber"),
});

const cargoDimensionsSchema = z.object({
    length: z.string(),
    width: z.string(),
    height: z.string(),
    weight: z.string(),
});

export const additionalInfoFormSchema = z
    .object({
        consigneeCompany: z.string().optional(),
        estimatedOffloadTime: z.number().optional(),
        contacts: z.array(consigneeSchema).optional(),
        offloadInstructions: z.string().optional(),
        cargoDimensions: z.array(cargoDimensionsSchema).optional(),
        branchNumber: z.string().optional(),
        invoiceNumber: z.string().optional(),
        sideLoading: z.boolean().optional(),
    })
    .nullish();

export const newOrderFormSchema = z
    .object({
        fromAddress: z.string().min(1, {
            message: "errorMessage.missingFromAddress",
        }),
        toAddress: z.string().min(1, {
            message: "errorMessage.missingToAddress",
        }),
        weight: z
            .number({
                invalid_type_error: "errorMessage.missingWeight",
                required_error: "errorMessage.missingWeight",
            })
            .positive({
                message: "errorMessage.missingWeight",
            })
            .min(1, {
                message: "errorMessage.missingWeight",
            }),
        numberOfEuPallets: z.number(),
        weightOfHeaviestPackage: z
            .number()
            .min(1, "errorMessage.missingWeightOfHeaviestPackage"),
        vehicleName: z.string().min(1, {
            message: "errorMessage.missingVehicle",
        }),
        deliveryInfo: z
            .object({
                deliveryDate: z.date().nullable(),
                earliestPickUp: z.string(),
                latestDelivery: z.string(),
                deliveryOption: z.union([
                    z.literal(DELIVERY_NAME.Scheduled),
                    z.literal(DELIVERY_NAME.Express),
                    z.literal(DELIVERY_NAME.Tomorrow),
                ]),
            })
            .refine((schema) => {
                const { deliveryDate, deliveryOption } = schema;

                if (deliveryOption !== DELIVERY_NAME.Scheduled) {
                    return true;
                }

                return deliveryDate !== null;
            }, "errorMessage.missingDeliveryDate")
            .refine((schema) => {
                const { earliestPickUp, deliveryOption } = schema;

                if (deliveryOption !== DELIVERY_NAME.Scheduled) {
                    return true;
                }

                return earliestPickUp.length > 0;
            }, "errorMessage.missingEarliestPickUp")
            .refine((schema) => {
                const { latestDelivery, deliveryOption } = schema;

                if (deliveryOption !== DELIVERY_NAME.Scheduled) {
                    return true;
                }

                return latestDelivery.length > 0;
            }, "errorMessage.missingLatestDelivery"),
        isSideLoaded: z.boolean(),
        orderNumber: z.string().min(1, {
            message: "errorMessage.missingOrderNumber",
        }),
        phone: z
            .string()
            .min(1, {
                message: "errorMessage.missingContactPhone",
            })
            .refine(
                (phone) => isValidPhoneNumber(phone),
                "errorMessage.invalidPhoneNumber"
            ),
        cargoContent: z.string().min(1, {
            message: "errorMessage.missingCargoContent",
        }),
        pricingHash: z.string(),
        priceCalculatedFrom: z.string().min(1, {
            message: "errorMessage.missingPriceCalculatedFrom",
        }),
        additionalInfo: additionalInfoFormSchema.nullish(),
    })
    .refine((schema) => {
        const { weightOfHeaviestPackage, weight } = schema;
        return weightOfHeaviestPackage <= weight;
    }, "errorMessage.weightOfHeaviestPackageIsBiggerThanWeight")
    .refine((schema) => {
        const { deliveryInfo, pricingHash } = schema;
        if (deliveryInfo.deliveryOption === "scheduled") {
            return !!pricingHash;
        }
        return true;
    }, "errorMessage.missingPriceHash");

export const contactForm = z
    .object({
        name: z.string().min(1, {
            message: "errorMessage.missingName",
        }),
        phone: z.string().nullable(),
        address: z.string(),
    })
    .refine((schema) => {
        const { phone } = schema;

        if (phone === "" || phone === null) {
            return true;
        }

        return isValidPhoneNumber(phone);
    }, "errorMessage.invalidPhoneNumber");

export const externalCarrierForm = z.object({
    name: z.string().min(1, {
        message: "errorMessage.missingName",
    }),
    phone: z
        .string()
        .refine(
            (phone) => (phone ? isValidPhoneNumber(phone) : true),
            "errorMessage.invalidPhoneNumber"
        ),
    email: z.string().email({ message: "errorMessage.invalidEmail" }),
    hourlyCost: z.string(),
    reference: z.string(),
});

export const stopDraftForm = z
    .object({
        toLocation: z.string().min(1, {
            message: "errorMessage.missingToAddress",
        }),
        phone: z.string(),
        cargoContent: z.string(),
        estimatedOffloadTime: z.string().default("15"),
        orderNumber: z.string().min(1, {
            message: "errorMessage.missingOrderNumber",
        }),
        stopTypeId: z.literal(1).or(z.literal(2)),
        contactId: z.number().nullable(),
        date: z.date().nullable(),
        time: z.string(),
        customerContactAt: z.union([z.literal("pickup"), z.literal("dropoff")]),
        projectReference: z.string().nullable(),
        weight: z.number().nullable(),
    })
    .refine((schema) => {
        const { phone } = schema;

        if (phone === "") {
            return true;
        }

        return isValidPhoneNumber(phone);
    }, "errorMessage.invalidPhoneNumber");

export const createStopDraftForm = z
    .object({
        fromLocation: z.string().min(1, {
            message: "errorMessage.missingFromAddress",
        }),
        toLocation: z.string().min(1, {
            message: "errorMessage.missingToAddress",
        }),
        pickupContactId: z.number().nullable(),
        dropoffContactId: z.number().nullable(),
        pickupPhone: z.string(),
        dropoffPhone: z.string(),
        orderNumber: z.string().min(1, {
            message: "errorMessage.missingOrderNumber",
        }),
        estimatedOffloadTime: z.string().default("15"),
        date: z.date().nullable(),
        pickupTime: z.string().nullable(),
        dropoffTime: z.string().nullable(),
        cargoContent: z.string(),
        pickupColor: z.string().nullable(),
        dropoffColor: z.string().nullable(),
        customerContactAt: z.union([z.literal("pickup"), z.literal("dropoff")]),
        projectReference: z.string(),
        weight: z.number(),
        tag: z.string().nullable(),
    })
    .refine((schema) => {
        const { pickupPhone, dropoffPhone } = schema;

        return (
            (pickupPhone !== "" ? isValidPhoneNumber(pickupPhone) : true) &&
            (dropoffPhone !== "" ? isValidPhoneNumber(dropoffPhone) : true)
        );
    }, "errorMessage.invalidPhoneNumber");

export const stopForm = z
    .object({
        fromLocation: z.string().min(1, {
            message: "errorMessage.missingFromAddress",
        }),
        toLocation: z.string().min(1, {
            message: "errorMessage.missingToAddress",
        }),
        pickupContactId: z.number().nullable(),
        dropoffContactId: z.number().nullable(),
        pickupPhone: z.string(),
        dropoffPhone: z.string(),
        orderNumber: z.string().min(1, {
            message: "errorMessage.missingOrderNumber",
        }),
        estimatedOffloadTime: z.string().default("15"),
        date: z.date().nullable(),
        pickupTime: z.string().nullable(),
        dropoffTime: z.string().nullable(),
        cargoContent: z.string(),
        pickupColor: z.string().nullable(),
        dropoffColor: z.string().nullable(),
        projectReference: z.string(),
        weight: z.number(),
        tag: z.string(),
        driverInstructions: z.string(),
    })
    .refine((schema) => {
        const { pickupPhone, dropoffPhone } = schema;

        return (
            (pickupPhone !== "" ? isValidPhoneNumber(pickupPhone) : true) &&
            (dropoffPhone !== "" ? isValidPhoneNumber(dropoffPhone) : true)
        );
    }, "errorMessage.invalidPhoneNumber");

export const createTourForm = z
    .object({
        preferredDriverId: z.string(),
        date: z.string().min(1, {
            message: "errorMessage.missingTourDate",
        }),
        time: z.string().min(1, {
            message: "errorMessage.missingTourTime",
        }),
        stopDraftIds: z.array(z.number()),
    })
    .refine((schema) => {
        const { date } = schema;
        // show error if chosen date is before today
        return !isBefore(new Date(date), startOfToday());
    }, "errorMessage.cannotCreateTourInPast")
    .refine((schema) => {
        const { stopDraftIds } = schema;
        return stopDraftIds.length >= 2;
    }, "errorMessage.missingTourStops");

export const updateTourForm = z.object({
    tourId: z.number(),
    date: z.string().min(1, {
        message: "errorMessage.missingTourDate",
    }),
    time: z.string().min(1, {
        message: "errorMessage.missingTourTime",
    }),
    preferredDriverId: z.string(),
});

export const profileForm = z.object({
    firstName: z.string().min(1, {
        message: "errorMessage.missingFirstName",
    }),
    lastName: z.string().min(1, {
        message: "errorMessage.missingLastName",
    }),
    phone: z
        .string()
        .min(1, {
            message: "errorMessage.missingPhoneNumber",
        })
        .refine(
            (phone) => isValidPhoneNumber(phone),
            "errorMessage.invalidPhoneNumber"
        ),
    locationId: z.number().nullable(),
});

export const favouriteForm = z.object({
    name: z.string().min(1, {
        message: "errorMessage.missingName",
    }),
    address: z.string().min(1, {
        message: "errorMessage.missingAddress",
    }),
    description: z.string(),
    type: z.literal("pickup").or(z.literal("dropoff")),
});

export const shareOrderForm = z.object({
    email: z.string().email({
        message: "errorMessage.invalidEmail",
    }),
});

export const driverForm = z
    .object({
        phone: z.string().min(1, {
            message: "errorMessage.missingPhoneNumber",
        }),
        firstName: z.string().min(1, {
            message: "errorMessage.missingFirstName",
        }),
        lastName: z.string().min(1, {
            message: "errorMessage.missingLastName",
        }),
        hourlyCost: z.string(),
        fuelTypeId: z.number().nullable(),
        fuelConsumption: z.string(),
        enabled: z.boolean().nullish(),
    })
    .refine((schema) => {
        const { phone } = schema;

        return isValidPhoneNumber(phone);
    }, "errorMessage.invalidPhoneNumber");

export const addDriverToLocationForm = z
    .object({
        driverPhone: z.string().min(1, {
            message: "errorMessage.missingPhoneNumber",
        }),
    })
    .refine((schema) => {
        const { driverPhone } = schema;

        return isValidPhoneNumber(driverPhone);
    }, "errorMessage.invalidPhoneNumber");

export const driverFormOld = z.object({
    email: z.string().email({
        message: "errorMessage.invalidEmail",
    }),
    hourlyCost: z.string(),
    enabled: z.boolean().nullish(),
    fuelTypeId: z.number().nullable(),
    fuelConsumption: z.string(),
});

export const markAsExternalStopsForm = z.object({
    date: z.string().min(1, {
        message: "errorMessage.missingDate",
    }),
    time: z.string().min(1, {
        message: "errorMessage.missingTime",
    }),
    externalCarrierId: z.string().min(1, {
        message: "errorMessage.missingExternalCarrier",
    }),
    reference: z.string(),
});

export const downloadStatsCsvForm = z
    .object({
        startDate: z.date().nullable(),
        endDate: z.date().nullable(),
        files: z
            .object({
                orders: z.boolean(),
                activity_logs: z.boolean(),
                online_logs: z.boolean(),
            })
            .refine((schema) => {
                const { orders, activity_logs, online_logs } = schema;

                return orders || activity_logs || online_logs;
            }, "errorMessage.noFilesSelected"),
    })
    .refine((schema) => !!schema.startDate, "errorMessage.missingStartDate")
    .refine((schema) => !!schema.endDate, "errorMessage.missingEndDate")
    .refine((schema) => {
        const { startDate, endDate } = schema;
        return startDate && endDate && isAfter(endDate, startDate);
    }, "errorMessage.endDateBeforeStartDate")
    .refine((schema) => {
        const { startDate, endDate } = schema;

        return (
            startDate && endDate && differenceInDays(endDate, startDate) <= 90
        );
    }, "errorMessage.csvTooBigDateRange");

export const stopChangeLocationForm = z.object({
    locationId: z.string().min(1, {
        message: "errorMessage.missingLocation",
    }),
    stopIds: z.array(z.number()).min(1, {
        message: "errorMessage.missingStopIds",
    }),
    notifyCustomerEmail: z.string(),
});

export type ConsigneeForm = z.infer<typeof consigneeSchema>;

export type CargoDimensionsForm = z.infer<typeof cargoDimensionsSchema>;

export type AdditionalInfoForm = z.infer<typeof additionalInfoFormSchema>;

export type NewOrderForm = z.infer<typeof newOrderFormSchema>;

export type StopDraftForm = z.infer<typeof stopDraftForm>;

export type CreateStopDraftForm = z.infer<typeof createStopDraftForm>;

export type StopForm = z.infer<typeof stopForm>;

export type CreateTourForm = z.infer<typeof createTourForm>;

export type UpdateTourForm = z.infer<typeof updateTourForm>;

export type ProfileForm = z.infer<typeof profileForm>;

export type FavouriteForm = z.infer<typeof favouriteForm>;

export type ShareOrderForm = z.infer<typeof shareOrderForm>;

export type ContactForm = z.infer<typeof contactForm>;

export type ExternalCarrierForm = z.infer<typeof externalCarrierForm>;

export type DriverForm = z.infer<typeof driverForm>;

export type AddDriverToLocationForm = z.infer<typeof addDriverToLocationForm>;

export type DriverFormOld = z.infer<typeof driverFormOld>;

export type MarkAsExternalStopsForm = z.infer<typeof markAsExternalStopsForm>;

export type DownloadStatsCsvForm = z.infer<typeof downloadStatsCsvForm>;

export type StopChangeLocationForm = z.infer<typeof stopChangeLocationForm>;
